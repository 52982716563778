import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { SchoolContext } from '../../App';

const SideMenu = () => {
  const { studentName } = useContext(SchoolContext);

  return (
    <div>
      <aside className="main-sidebar" >
        {/* sidebar */}
        <div className="sidebar" >
          {/* Sidebar user panel */}
          <div className="user-panel" >
            <div className="image pull-left" >
              <img src="assets/dist/img/avatar5.png" className="img-circle" alt="User Image" />
            </div>
            <div className="info">
              <h4>Welcome</h4>
              <p>{studentName ? (studentName) : null}</p>
            </div>
          </div>
          {/* sidebar menu */}
          <ul className="sidebar-menu" >
            <li className="active">
              <Link to="/student/welcome"><i className="fa fa-hospital-o" /><span>Dashboard</span></Link>
            </li>




            <li className="treeview">
              <a href="#">
                <i className="fa fa-money" /><span>Fees</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right" />
                </span>
              </a>
              <ul className="treeview-menu">
                <li>
                  <Link to="/student/feescard">My Fees Card</Link>
                </li>
                <li>
                  <Link to="/student/feesreceipts">Fees Receipts</Link>
                </li>

              </ul>
            </li>









          </ul>
        </div> {/* /.sidebar */}
      </aside>
    </div>
  )
}

export default SideMenu