import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';

const AddFines = () => {
    const { schoolID, schoolSession, schoolUserType } = useContext(SchoolContext);

    const [fineType, setFineType] = useState("");
    const [fineAfterDays, setFineAfterDays] = useState("");
    const [fineAmount, setFineAmount] = useState("");

    const [fines, setFines] = useState([]);
    const [randDep, setRandDep] = useState(0);
    const [errors, setErrors] = useState([]);

    const [months, setMonths] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }
        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getFines', qs.stringify(userData))
            .then((response) => {
                setFines(response.data.data);
            });
    }, [randDep]);


    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const userData = {
            fineType: fineType,
            fineAfterDays: fineAfterDays,
            fineAmount: fineAmount,
            school_id:schoolID,
            session:schoolSession,
        }
        Axios.post(api_url + 'addFines', qs.stringify(userData))
            .then(resp => {
                setLoading(false);
                if (resp.data.status == 'OK') {
                    toast.success('Created successfully', {
                        position: "top-center",
                    });
                    setFineType("");
                    setFineAfterDays("");
                    setFineAmount("");
                    setErrors([]);
                }
                else
                    if (resp.data.status == 'DUPLICATE')
                     {
                        toast.error('This record is dupicate', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                else
                    if (resp.data.status == 'V_ERROR') {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                    else {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data.message);
                    }
                setRandDep(Math.random());
            });
    };

    const deleteFines = (id) => {
        const delData = {
            id: id
        }
        Axios.put(api_url + 'deleteFines/', qs.stringify(delData))
            .then((resp) => {
                if (resp.data.status == 'OK') {
                    toast.success('Deleted Successfully', {
                        position: "top-center",
                    });

                    setRandDep(Math.random());
                }
            })
    }

    return (
        <div>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">

                <section className="content-header">
                    <div className="header-icon">
                        <i className="pe-7s-note2" />
                    </div>
                    <div className="header-title">
                        <h1>Fines</h1>
                        <small>Add Fines</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        {/* Form controls */}
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">

                                <ToastContainer />

                                <div className="panel-body">
                                    <form className="col-sm-12" onSubmit={onSubmit}>


                                    <div className="col-sm-7 form-group" style={{ marginTop: -10 }}>
                                            <label>Fine Type</label>
                                            <select className="form-control" id="exampleSelect1" value={fineType} required name="fee_type" onChange={(event) => { setFineType(event.target.value) }}>
                                                <option value="">Select</option>
                                                <option value="Late Fees">Late Fees</option>
                                                <option value="Short Attendance">Short Attendance</option>
                                            </select>
                                            <p className='errorMessage'>{errors.fineType}</p>
                                     </div>

                                     <div className="col-sm-7 form-group" style={{ marginTop: -10 }}>
                                            <label>Fine After Days</label>
                                            <input type="number" className="form-control" required value={fineAfterDays} placeholder="Fine charged after days" min="0" onChange={(e) => setFineAfterDays(e.target.value)} />
                                            <p className='errorMessage'>{errors.fineAfterDays}</p>
                                        </div>


                                        <div className="col-sm-7 form-group" style={{ marginTop: -10 }}>
                                            <label>Fine Amount(Per Day)</label>
                                            <input type="number" className="form-control" required value={fineAmount} placeholder="Fine amount per day" min="0" onChange={(e) => setFineAmount(e.target.value)} />
                                            <p className='errorMessage'>{errors.fineAmount}</p>
                                        </div>

                                        <div className="col-sm-12">
                                            {
                                                !loading ? (
                                                    <button type='submit' className="btn btn-success">Save Fine</button>)
                                                    :
                                                    (<div className='loader'>
                                                        <Circles
                                                            height="60"
                                                            width="60"
                                                            color="#4fa94d"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={loading}
                                                        />
                                                    </div>)
                                            }
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> {/* /.content */}











                <section className="content" >
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-heading">
                                    <div className="btn-group">
                                        <h3>Existing Records</h3>
                                    </div>
                                </div>
                                <div className="panel-body">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Serial No</th>
                                                    <th>Fine Type</th>
                                                    <th>Fee After Days</th>

                                                    <th>Fine Amount(Rs Per Day)</th>
                                                    <th>Created At</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    fines.length ?
                                                    fines.map((value, key) => {
                                                           
                                                            return (<tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{value.fineType}</td>

                                                                <td >{value.fineAfterDays}</td>
                                                                <td >{value.fineAmount}</td>

                                                                <td>{Moment(value.created_at).format("DD-MM-YYYY, h:m:s")}</td>
                                                                <td style={{width:'15%',minWidth:'100'}}>

                                                            
                                                                    <Link
                                                                        to={{ pathname: `/EditFines` }}
                                                                        state={{                                                        
                                                                            id: value.id,
                                                                            fineType: value.fineType,
                                                                            fineAfterDays: value.fineAfterDays,
                                                                            fineAmount: value.fineAmount,                                                                        
                                                                            school_id: value.school_id,
                                                                            session: value.session
                                                                        }}>
                                                                        <button type="button" className="btn btn1 btn-info btn-xs" data-toggle="modal" data-target="#ordine" style={{ marginBottom: 5 }}><i className="fa fa-pencil" />
                                                                        </button>
                                                                    </Link>


                                                                    <button type="button" className="btn btn1 btn-danger btn-xs" onClick={() => window.confirm('Are you sure you wish to delete this item?') ? deleteFines(value.id) : ""}><i className="fa fa-trash-o" />
                                                                    </button>
                                                                </td>
                                                            </tr>);
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan='5'>To record found!</td>
                                                        </tr>
                                                }


                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div> {/* /.content-wrapper */}

        </div>
    )
}

export default AddFines