import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';

const ViewStudents = () => {

    const { schoolID, schoolSession } = useContext(SchoolContext);

    const [classes, setClasses] = useState([]);
    const [classId, setClassId] = useState("");
    const [category, setCategory] = useState("");


    const [randDep, setRandDep] = useState(0);
    const [students, setStudents] = useState([]);
    const [studentsAll, setStudentsAll] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);


    const getClasses = () => {
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }

        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getClasses', qs.stringify(userData))
            .then((response) => {
                setClasses(response.data.data)
            })
    }


    useEffect(() => {
        setLoading(true);
        getClasses();
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }

        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getStudents', qs.stringify(userData))
            .then((response) => {
                setStudents(response.data.data);
                setStudentsAll(response.data.data);
                setLoading(false);
            })
    }, [randDep]);


    const deleteStudent = (id) => {
        const delData = {
            id: id
        }
        Axios.put(api_url + 'deleteStudent/', qs.stringify(delData))
            .then((resp) => {
                if (resp.data.status == 'OK') {
                    toast.success('Deleted Successfully', {
                        position: "top-center",
                    });

                    setRandDep(Math.random());
                }
            })
    }




    const handleOnSearchInput = async text => {
        setSearchQuery(text);
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }

        const filteredData = studentsAll.filter(student => {
            if ((student.reg_no.toLowerCase().includes(text.toLowerCase())) || (student.name.toLowerCase().includes(text.toLowerCase())) || (student.contact_no.toLowerCase().includes(text.toLowerCase())) || (student.class_name.toLowerCase().includes(text.toLowerCase()))) {
                return student;
            }
        });


        if (filteredData.length) {
            setStudents([...filteredData]);
        }
        else {
            setStudents([]);
        }
    };

    const classChangeHandler = (event) => {
        let text = event.target.value;
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }

        const filteredData = studentsAll.filter(student => {
            if ((student.class_id == text)) {
                return student;
            }
        });


        if (filteredData.length) {
            setStudents([...filteredData]);
        }
        else {
            setStudents([]);
        }

        setClassId(text);

    };


    const categoryChangeHandler = (event) => {
        let text = event.target.value;
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }

        const filteredData = studentsAll.filter(student => {
            if ((student.category == text)) {
                return student;
            }
        });


        if (filteredData.length) {
            setStudents([...filteredData]);
        }
        else {
            setStudents([]);
        }

        setCategory(text);

    };

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Students</h1>
                        <small>Students list</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-heading">
                                    <div className="btn-group">
                                        <Link to="/AddStudent" className="btn btn-success"><i className="fa fa-plus"></i>
                                            &nbsp;Add New Student
                                        </Link>
                                    </div>
                                </div>




                                <div className="panel-body">

                                    <div className="row">
                                        <div className="panel-header">


                                            <div className="col-md-12 col-sm-6 col-xs-12">
                                                <div className="dataTables_length">
                                                    <div className="row">

                                                     
                                                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                                                <input type="text" className="form-control" placeholder="search by reg no, name, contact no or class" onChange={(event) => { handleOnSearchInput(event.target.value) }} />
                                                            </div>

                                                            <div className='col-lg-2 col-md-3 col-sm-6'>
                                                                <select className="form-control" value={classId} required name="class" onChange={classChangeHandler} >
                                                                    <option value="">Select Class</option>
                                                                    {
                                                                        classes.length ?
                                                                            classes.map((value) => {
                                                                                return (<option key={value.id} value={value.id}>{value.name}</option>);
                                                                            })
                                                                            :
                                                                            <option value="">Not Record Found!</option>
                                                                    }
                                                                </select>
                                                            </div>



                                                            <div className='col-lg-2 col-md-3 col-sm-6'>
                                                                <select className="form-control" value={category} onChange={categoryChangeHandler} >
                                                                    <option value="">Select Category</option>
                                                                    <option value="General">General</option>
                                                                    <option value="OBC">OBC</option>
                                                                    <option value="SC">SC</option>
                                                                    <option value="ST">ST</option>
                                                                    <option value="Physically Disabled">Physically Disabled</option>
                                                                </select>
                                                            </div>
                                                       










                                                        {/* <span className="input-group-btn">
                                                            <button className="btn btn-primary" type="button" >
                                                                <span className="glyphicon glyphicon-search" />
                                                            </button>
                                                </span> */}






                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        {
                                            !loading ? (
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Reg No</th>
                                                            <th>Password</th>
                                                            <th>Name</th>
                                                            <th>Father Name</th>

                                                            <th>Contact No</th>
                                                            <th>Gender</th>
                                                            <th>Class</th>

                                                            <th>Created At</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            students.length ?
                                                                students.map((value, key) => {

                                                                    return (<tr key={key}>
                                                                        <td>
                                                                            <img src={
                                                                                value.studentPhoto ? (value.studentPhoto) : "assets/dist/img/avatar.png"
                                                                            } className="border-green hidden-xs hidden-sm" style={{ width: 20, height: 20, borderRadius: '50%' }} />
                                                                            {value.reg_no}
                                                                        </td>
                                                                        <td>{value.password}</td>
                                                                        <td>{value.name}</td>
                                                                        <td>{value.father_name}</td>

                                                                        <td>{value.contact_no}</td>
                                                                        <td>{value.gender}</td>
                                                                        <td>{value.class_name}</td>

                                                                        <td>{Moment(value.created_at).format("DD-MM-YYYY, h:m:s")}</td>
                                                                        <td>
                                                                            <Link
                                                                                to={{ pathname: `/EditStudent` }}
                                                                                state={{
                                                                                    id: value.id,
                                                                                    regNo: value.reg_no,
                                                                                    rollNo: value.rollNo,
                                                                                    classId: value.class_id,

                                                                                    section: value.section,
                                                                                    name: value.name,
                                                                                    lastName: value.lastName,

                                                                                    gender: value.gender,
                                                                                    dob: value.dob,
                                                                                    category: value.category,

                                                                                    caste: value.caste,
                                                                                    religion: value.religion,
                                                                                    contactNo: value.contact_no,

                                                                                    email: value.email,
                                                                                    doa: value.doa,
                                                                                    studentPhoto: value.studentPhoto,

                                                                                    studentAdhaarNo: value.studentAdhaarNo,
                                                                                    studentAdhaarPhoto: value.studentAdhaarPhoto,
                                                                                    birthNo: value.birthNo,
                                                                                    birthPhoto: value.birthPhoto,

                                                                                    bloodGroup: value.bloodGroup,
                                                                                    houseId: value.houseId,
                                                                                    height: value.height,

                                                                                    weight: value.weight,
                                                                                    dom: value.dom,

                                                                                    fatherName: value.father_name,
                                                                                    fatherContactNo: value.fatherContactNo,
                                                                                    fatherOccupation: value.fatherOccupation,
                                                                                    fatherPhoto: value.fatherPhoto,
                                                                                    fatherAdhaarNo: value.fatherAdhaarNo,

                                                                                    motherName: value.motherName,
                                                                                    motherContactNo: value.motherContactNo,
                                                                                    motherOccupation: value.motherOccupation,
                                                                                    motherPhoto: value.motherPhoto,
                                                                                    motherAdhaarNo: value.motherAdhaarNo,

                                                                                    guardianName: value.guardianName,
                                                                                    guardianRelation: value.guardianRelation,
                                                                                    guardianEmail: value.guardianEmail,
                                                                                    guardianPhoto: value.guardianPhoto,
                                                                                    guardianContactNo: value.guardianContactNo,
                                                                                    guardianOccupation: value.guardianOccupation,
                                                                                    guardianAddress: value.guardianAddress,


                                                                                    currentAddress: value.currentAddress,
                                                                                    permanantAddress: value.permanantAddress,


                                                                                    password: value.password,

                                                                                    school_id: value.school_id,
                                                                                    session: value.session
                                                                                }}>
                                                                                <button type="button" className="btn btn1 btn-info btn-xs" data-toggle="modal" data-target="#ordine"><i className="fa fa-pencil" />
                                                                                </button>
                                                                            </Link>


                                                                            {
                                                                                value.cash_report_id == null ? (<button type="button" className="btn btn1 btn-danger btn-xs" onClick={() => window.confirm('Are you sure you wish to delete this item?') ? deleteStudent(value.id) : ""}><i className="fa fa-trash-o" />
                                                                                </button>) : (null)
                                                                            }

                                                                        </td>
                                                                    </tr>);
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan='9'>No record found!</td>
                                                                </tr>
                                                        }

                                                        <tr>
                                                            <td colSpan="9" className='table_bottom_total'>Total {students.length} records found</td>
                                                        </tr>


                                                    </tbody>
                                                </table>)
                                                :
                                                (<div className='loader' style={{ marginLeft: '20%' }}>
                                                    <Circles
                                                        height="60"
                                                        width="60"
                                                        color="#4fa94d"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={loading}
                                                    />
                                                </div>)
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default ViewStudents