import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import SideMenu from './SideMenu';

const WelcomeStudent = () => {


    return (
        <div>

             <Header></Header>
            <SideMenu></SideMenu>


<>
  <div className="content-wrapper">
    {/* Content Header (Page header) */}
    <section className="content-header">
      <form
        action="#"
        method="get"
        className="sidebar-form search-box pull-right hidden-md hidden-lg hidden-sm"
      >
        <div className="input-group">
          <input
            type="text"
            name="q"
            className="form-control"
            placeholder="Search..."
          />
          <span className="input-group-btn">
            <button type="submit" name="search" id="search-btn" className="btn">
              <i className="fa fa-search" />
            </button>
          </span>
        </div>
      </form>
      <div className="header-icon">
        <i className="fa fa-tachometer" />
      </div>
      <div className="header-title">
        <h1> Dashboard</h1>
        <small> Dashboard features</small>
        <ol className="breadcrumb hidden-xs">
          <li>
            <a href="<?php echo base_url(); ?>">
              <i className="pe-7s-home" /> Home
            </a>
          </li>
          <li className="active">Dashboard</li>
        </ol>
      </div>
    </section>
    {/* Main content */}
    <section className="content">
      <div className="row">
        <a href="#">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <div className="panel panel-bd cardbox">
              <div className="panel-body">
                <div className="items pull-left">
                  <i className="fa fa-plus-square fa-2x" />
                  <h4>Tile 1</h4>
                </div>
              </div>
            </div>
          </div>
        </a>
        <a href="#">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <div className="panel panel-bd cardbox">
              <div className="panel-body">
                <div className="items pull-left">
                  <i className="fa fa-plus-square fa-2x" />
                  <h4>Tile 2</h4>
                </div>
              </div>
            </div>
          </div>
        </a>
        <a href="#">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <div className="panel panel-bd cardbox">
              <div className="panel-body">
                <div className="items pull-left">
                  <i className="fa fa-plus-square fa-2x" />
                  <h4>Tile 3</h4>
                </div>
              </div>
            </div>
          </div>
        </a>
        <a href="#">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3">
            <div className="panel panel-bd cardbox">
              <div className="panel-body">
                <div className="items pull-left">
                  <i className="fa fa-plus-square fa-2x" />
                  <h4>Tile 4</h4>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </section>{" "}
    {/* /.content */}
  </div>{" "}
  {/* /.content-wrapper */}
</>


           



        </div>
    )
}

export default WelcomeStudent