import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';


const GenerateTC = () => {
    const navigate = useNavigate();
    const { schoolID, schoolSession } = useContext(SchoolContext);

    const [errors, setErrors] = useState([]);
    const [randDep, setRandDep] = useState(0);
    const [regNo, setRegNo] = useState("");
    const [studentDetails, setStudentDetails] = useState([]);

    const [reasonForLeaving, setReasonForLeaving] = useState("");
    const [generalConduct, setGeneralConduct] = useState("");
    const [nccCadet, setNccCadet] = useState("");

    const [gamePlayed, setGamePlayed] = useState("");
    const [otherRemarks, setOtherRemarks] = useState("");
    const [applicationDate, setApplicationDate] = useState("");
    const [issueDate, setIssueDate] = useState("");

    const [tcID, setTCID] = useState("");




    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);



    const handleRegNoChange = async () => {
        setTCID("");
        setReasonForLeaving("");
        setGeneralConduct("");
        setNccCadet("");
        setGamePlayed("");
        setOtherRemarks("");
        setApplicationDate("");
        setIssueDate("");

        setLoading1(true);
        try {
            if (schoolID < 1 || schoolID == null) {
                setRandDep(Math.random);
            }

            const userData = {
                regNo: regNo,
                schoolId: schoolID,
                session: schoolSession,
            }

            await Axios.post(api_url + 'studentTCDetails', qs.stringify(userData))
                .then(resp => {
                    setLoading1(false);
                    if (resp.data['message'] == 'OK') {
                        setStudentDetails(resp.data.student_details[0]);
                        if (resp.data.data[0]) {
                            setTCID(resp.data.data[0].id);
                            setReasonForLeaving(resp.data.data[0].reasonForLeaving);
                            setGeneralConduct(resp.data.data[0].generalConduct);
                            setNccCadet(resp.data.data[0].nccCadet);

                            setGamePlayed(resp.data.data[0].gamePlayed);
                            setOtherRemarks(resp.data.data[0].otherRemarks);
                            setApplicationDate(resp.data.data[0].applicationDate);

                            setIssueDate(resp.data.data[0].issueDate);
                        }

                    }
                    else
                        if (resp.data.status == 'FEES_NOT_ASSIGNED') {
                            toast.error('Please assign fees to classes', {
                                position: "top-center",
                            });
                            setStudentDetails([]);
                        }
                        else
                            if (resp.data.status == 'INVALID_STUDENT') {
                                toast.error('Student not found', {
                                    position: "top-center",
                                });
                            }
                            else {
                                toast.error('Error occured in fees card setup', {
                                    position: "top-center",
                                });
                            }

                });

        }
        catch (err) {
            console.log(err);
        }

    }

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const userData = {
            tcID: tcID,
            reg_no: regNo,
            reasonForLeaving: reasonForLeaving,
            generalConduct: generalConduct,

            nccCadet: nccCadet,
            gamePlayed: gamePlayed,
            otherRemarks: otherRemarks,

            applicationDate: applicationDate,
            issueDate: issueDate,

            school_id: schoolID,
            session: schoolSession,
        }

        Axios.post(api_url + 'addTC', qs.stringify(userData))
            .then(resp => {
                setLoading(false);
                if (resp.data.status == 'OK') {
                    toast.success('Updated successfully', {
                        position: "top-center",
                    });
                    setRegNo("");
                    setReasonForLeaving("");
                    setGeneralConduct("");

                    setNccCadet("");
                    setGamePlayed("");
                    setOtherRemarks("");

                    setApplicationDate("");
                    setIssueDate("");

                    setStudentDetails([]);

                    setErrors([]);
                }
                else
                    if (resp.data.status == 'DUPLICATE') {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                    else
                        if (resp.data.status == 'V_ERROR') {
                            toast.error('Cannot create try again', {
                                position: "top-center",
                            });
                            setErrors(resp.data.data);
                        }
                        else {
                            toast.error('Cannot create try again', {
                                position: "top-center",
                            });
                            setErrors(resp.data.message);
                        }
                setRandDep(Math.random());
            });

    };

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>TC</h1>
                        <small>Generate TC</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">

                            <div className="panel-heading">
                                    <div className="btn-group">
                                        <Link className="btn btn-primary" to="/TCRecords"> <i className="fa fa-list" />  TC List </Link>
                                    </div>
                                </div>


                                <div className="panel-body">

                                    <div className="row">
                                        <div className="panel-header">


                                            <div className="col-sm-6 col-xs-12">
                                                <div className="dataTables_length">
                                                    <div className="input-group custom-search-form">



                                                        <input type="text" className="form-control" value={regNo} placeholder="search by reg no" onChange={(event) => setRegNo(event.target.value)} />
                                                        <span className="input-group-btn">


                                                            <>
                                                                {
                                                                    !loading1 ? (
                                                                        <button className="btn btn-primary" type="button" onClick={handleRegNoChange}>
                                                                            <span className="glyphicon glyphicon-search" />
                                                                        </button>
                                                                    )
                                                                        :
                                                                        (<div className='loader'>
                                                                            <Circles
                                                                                height="40"
                                                                                width="40"
                                                                                color="#4fa94d"
                                                                                ariaLabel="circles-loading"
                                                                                wrapperStyle={{}}
                                                                                wrapperClass=""
                                                                                visible={loading1}
                                                                            />
                                                                        </div>)
                                                                }
                                                            </>


                                                        </span>




                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>











                                    {
                                        studentDetails.name ? (<div className="table-responsive">

                                            <div className="inbox-avatar" style={{ borderBottom: '1px solid #eee', borderTop: '1px solid #eee', marginBottom: 10 }}>
                                                <img src="assets/dist/img/avatar.png" className="border-green hidden-xs hidden-sm" />
                                                <div className="inbox-avatar-text">
                                                    <div className="avatar-name" style={{ marginBottom: 3 }}>{studentDetails.name} ({studentDetails.reg_no})</div>
                                                    <div>
                                                        <span className="bg-green badge avatar-text">
                                                            Father Name  : {studentDetails.father_name}
                                                        </span>

                                                        <span className="badge avatar-text" style={{ backgroundColor: '#7A316F' }}>
                                                            Class  : {studentDetails.class_name}
                                                        </span>

                                                        <span className="bg-gray badge avatar-text">
                                                            Contact No  : {studentDetails.contact_no}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>











                                            <div className="panel-body" >
                                                <form className="col-sm-12" onSubmit={onSubmit}>


                                                    <div className="">
                                                        <div className="col-sm-6 form-group">
                                                            <label>Reason For Leaving<span className='required_field'>*</span></label>
                                                            <input type="text" className="form-control" value={reasonForLeaving} required autoFocus onChange={(event) => { setReasonForLeaving(event.target.value) }} />
                                                            <p className='errorMessage'>{errors.reasonForLeaving}</p>
                                                        </div>

                                                        <div className="col-sm-6 form-group">
                                                            <label>General Conduct</label>
                                                            <input type="text" className="form-control" value={generalConduct} onChange={(event) => { setGeneralConduct(event.target.value) }} />
                                                            <p className='errorMessage'>{errors.generalConduct}</p>
                                                        </div>

                                                        <div className="col-sm-6 form-group">
                                                            <label>NCC Cadet</label>
                                                            <input type="text" className="form-control" value={nccCadet} onChange={(event) => { setNccCadet(event.target.value) }} />
                                                            <p className='errorMessage'>{errors.nccCadet}</p>
                                                        </div>

                                                        <div className="col-sm-6 form-group">
                                                            <label>Any Game Played</label>
                                                            <input type="text" className="form-control" value={gamePlayed} onChange={(event) => { setGamePlayed(event.target.value) }} />
                                                            <p className='errorMessage'>{errors.gamePlayed}</p>
                                                        </div>

                                                        <div className="col-sm-6 form-group">
                                                            <label>Any Other Remarks</label>
                                                            <input type="text" className="form-control" value={otherRemarks} onChange={(event) => { setOtherRemarks(event.target.value) }} />
                                                            <p className='errorMessage'>{errors.otherRemarks}</p>
                                                        </div>


                                                        <div className="col-sm-6 form-group">
                                                            <label>TC Application Date</label>
                                                            <input type="date" className="form-control" required value={applicationDate} onChange={(event) => { setApplicationDate(event.target.value) }} />
                                                            <p className='errorMessage'>{errors.applicationDate}</p>
                                                        </div>

                                                        <div className="col-sm-6 form-group">
                                                            <label>TC Issue Date</label>
                                                            <input type="date" className="form-control" required value={issueDate} onChange={(event) => { setIssueDate(event.target.value) }} />
                                                            <p className='errorMessage'>{errors.issueDate}</p>
                                                        </div>





                                                    </div>
                                                    <div className="col-sm-12 reset-button">
                                                        {
                                                            !loading ? (
                                                                <button type='submit' className="btn btn-success">SAVE</button>)
                                                                :
                                                                (<div className='loader'>
                                                                    <Circles
                                                                        height="60"
                                                                        width="60"
                                                                        color="#4fa94d"
                                                                        ariaLabel="circles-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClass=""
                                                                        visible={loading}
                                                                    />
                                                                </div>)
                                                        }

                                                    </div>


                                                </form>
                                            </div>


                                        </div>) : (
                                            <div style={{ color: 'red' }}>Please enter valid reg no</div>
                                        )
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </section>








            </div>


        </>
    )
}

export default GenerateTC