import React, { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import { SchoolContext } from '../App';
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const { schoolName, setSchoolName, schoolID, setSchoolID, schoolSession, setSchoolSession, schoolUserType, setSchoolUserType } = useContext(SchoolContext);

  const [session, setSession] = useState("");
  const [randDep, setRandDep] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // const setLocalItems = () =>
    // {
    //   let localSchoolName = localStorage.getItem('schoolName');
    //   if(localSchoolName != '')
    //   {
    //       setSchoolName(localSchoolName);
    //   }

    //   let localSchoolID = localStorage.getItem('schoolID');
    //   if(localSchoolID != '')
    //   {
    //       setSchoolID(localSchoolID);
    //   }

    //   let localSchoolSession = localStorage.getItem('schoolSession');
    //   if(localSchoolSession != '')
    //   {
    //       setSchoolSession(localSchoolSession);
    //   }

    //   let localSchoolUserType = localStorage.getItem('schoolUserType');
    //   if(localSchoolUserType != '')
    //   {
    //       setSchoolUserType(localSchoolUserType);
    //   }
    // }
    // setLocalItems();
  }, [])

  useEffect(() => {
    const getLatestSession = () => {
      if(schoolUserType == 'SCHOOL')
      {
         //navigate('/WelcomeSchool');
      }

      Axios.get(api_url + 'getLatestSession/')
        .then((response) => {
          setSession(response.data.data)
        })
    }

    getLatestSession();
  }, [randDep]);


  console.log(schoolID);


  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const userData = {
      email: email,
      password: password,
    }

    Axios.post(api_url + 'schoolLogin', qs.stringify(userData))
      .then(resp => {
        //console.log(resp);
        setLoading(false);
        if (resp.data.status == 'OK') {
          toast.success('Login successfully', {
            position: "top-center",
          });
          setEmail("");
          setPassword("");
          setErrors([]);
         
          // setSchoolName(resp.data.name);
          // setSchoolID(resp.data.id);
          // setSchoolSession(session);
          // setSchoolUserType("SCHOOL");

          localStorage.setItem('loginToken', resp.data.loginToken);
          localStorage.setItem('schoolSession', session);
          localStorage.setItem('schoolUserType', 'SCHOOL');
          navigate('/WelcomeSchool');
          //localStorage.setItem('schoolID', schoolID);         
          // localStorage.setItem('schoolUserType', schoolUserType);

          // if(schoolUserType == 'SCHOOL')
          // {
          //   navigate('/WelcomeSchool');
          // }

         


        }
        else
          if (resp.data.status == 'NOT_FOUND') {
            toast.error(resp.data.message, {
              position: "top-center",
            });
            setErrors(resp.data);
          }
          else
            if (resp.data.status == 'V_ERROR') {
              toast.error('Cannot login try again', {
                position: "top-center",
              });
              setErrors(resp.data);
            }
            else {
              toast.error('Cannot login try again', {
                position: "top-center",
              });
              setErrors(resp.data.message);
            }
        setRandDep(Math.random());



      });


  };

  return (
    <div>
      <div className="login-wrapper">
        <div className="container-center">
          <div className="panel panel-bd">
            <div className="panel-heading">
              <div className="view-header">
                <div className="header-icon">
                  <i className="pe-7s-unlock" />
                </div>
                <div className="header-title">
                  <h3>School Login</h3>
                  <small>
                    <strong>Please enter your credentials to login.</strong>
                  </small>
                </div>
              </div>
            </div>
            <ToastContainer />
            <div className="panel-body">

              <form className="col-sm-12" onSubmit={onSubmit} style={{padding:20}}>

                <div className="form-group">
                  <label className="control-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    required=""
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className='errorMessage'>{errors.email}</p>
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="password">
                    Password
                  </label>
                  <input
                    type="password"
                    placeholder="******"
                    required=""
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <p className='errorMessage'>{errors.password}</p>
                </div>

                <div className="col-sm-12 reset-button">
                  {
                    !loading ? (
                      <button className="btn btn-primary" type="submit">Login</button>
                    )
                      :
                      (<div className='loader'>
                        <Circles
                          height="60"
                          width="60"
                          color="#4fa94d"
                          ariaLabel="circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={loading}
                        />
                      </div>)
                  }



                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Login