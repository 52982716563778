import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';
import Moment from 'moment';

const UpdateStudent = () => {

    const location = useLocation();
    const id = location.state.id;
    const navigate = useNavigate();

   
    const [classes, setClasses] = useState([]);

    const [regNo, setRegNo] = useState(location.state.regNo);
    const [rollNo, setRollNo] = useState(location.state.rollNo);
    const [classId, setClassId] = useState(location.state.classId);
    const [section, setSection] = useState(location.state.section);

    const [name, setName] = useState(location.state.name);
    const [lastName, setLastName] = useState(location.state.lastName);
    const [gender, setGender] = useState(location.state.gender);

    const [dob, setDob] = useState(location.state.dob);
    const [category, setCategory] = useState(location.state.category);
    const [caste, setCaste] = useState(location.state.caste);
    const [religion, setReligion] = useState(location.state.religion);

    
    const [contactNo, setContactNo] = useState(location.state.contactNo);
    const [email, setEmail] = useState(location.state.email);

    let crDate = Moment(new Date()).format("YYYY-MM-DD");
    const [doa, setDoa] = useState(location.state.doa);

    const [studentPhoto, setStudentPhoto] = useState(location.state.studentPhoto);
    const [studentAdhaarNo, setStudentAdhaarNo] = useState(location.state.studentAdhaarNo);
    const [studentAdhaarPhoto, setStudentAdhaarPhoto] = useState(location.state.studentAdhaarPhoto);

    const [birthNo, setBirthNo] = useState(location.state.birthNo);
    const [birthPhoto, setBirthPhoto] = useState(location.state.birthPhoto);
    const [bloodGroup, setBloodGroup] = useState(location.state.bloodGroup);

    const [houses, setHouses] = useState([]);
    const [houseId, setHouseId] = useState(location.state.houseId);
    const [height, setHeight] = useState(location.state.height);

    const [weight, setWeight] = useState(location.state.weight);
    const [dom, setDom] = useState(location.state.dom);

    const [fatherName, setFatherName] = useState(location.state.fatherName);
    const [fatherContactNo, setFatherContactNo] = useState(location.state.fatherContactNo);
    const [fatherOccupation, setFatherOccupation] = useState(location.state.fatherOccupation);
    const [fatherPhoto, setFatherPhoto] = useState(location.state.fatherPhoto);
    const [fatherAdhaarNo, setFatherAdhaarNo] = useState(location.state.fatherAdhaarNo);


    const [motherName, setMotherName] = useState(location.state.motherName);
    const [motherContactNo, setMotherContactNo] = useState(location.state.motherContactNo);
    const [motherOccupation, setMotherOccupation] = useState(location.state.motherOccupation);
    const [motherPhoto, setMotherPhoto] = useState(location.state.motherPhoto);
    const [motherAdhaarNo, setMotherAdhaarNo] = useState(location.state.motherAdhaarNo);

    const [guardian, setGuardian] = useState("");

    const [guardianName, setGuardianName] = useState(location.state.guardianName);
    const [guardianRelation, setGuardianRelation] = useState(location.state.guardianRelation);
    const [guardianEmail, setGuardianEmail] = useState(location.state.guardianEmail);
    const [guardianPhoto, setGuardianPhoto] = useState(location.state.guardianPhoto);

    const [guardianContactNo, setGuardianContactNo] = useState(location.state.guardianContactNo);
    const [guardianOccupation, setGuardianOccupation] = useState(location.state.guardianOccupation);
    const [guardianAddress, setGuardianAddress] = useState(location.state.guardianAddress);

    const [currentAddress, setCurrentAddress] = useState(location.state.currentAddress);
    const [permanantAddress, setpermanantAddress] = useState(location.state.permanantAddress);

    const [currentAddGuardAdd, setCurrentAddGuardAdd] = useState(false);
    const [permAddGuardAdd, setPermAddGuardAdd] = useState(false);

   
    const [errors, setErrors] = useState([]);
    const [randDep, setRandDep] = useState(0);

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const userData = {
            school_id: location.state.school_id,
            session: location.state.session,
        }
        Axios.post(api_url + 'getClasses', qs.stringify(userData))
            .then((response) => {
                setClasses(response.data.data)
            })
        Axios.post(api_url + 'getHouses', qs.stringify(userData))
            .then((response) => {
                setHouses(response.data.data)
            })

    }, [randDep]);


    const setGuardianType = (value) => {
        if (value == 'Father') {
            setGuardianName(fatherName);
            setGuardianRelation(value);
            setGuardianContactNo(fatherContactNo);
            setGuardianOccupation(fatherOccupation);
            setGuardianPhoto(fatherPhoto);
        }
        else
            if (value == 'Mother') {
                setGuardianName(motherName);
                setGuardianRelation(value);
                setGuardianContactNo(motherContactNo);
                setGuardianOccupation(motherOccupation);
                setGuardianPhoto(motherPhoto);
            }
            else {
                setGuardianName("");
                setGuardianRelation("Other");
                setGuardianContactNo("");
                setGuardianOccupation("");
                setGuardianPhoto("");
            }

    }

    const makeCurrentAddressAsGuardianAddress = (value) => {
        setCurrentAddGuardAdd(!value);
        if (currentAddGuardAdd) {
            setCurrentAddress("");
        }
        else {
            setCurrentAddress(guardianAddress);
        }
    }

    const makePermAddressAsGuardianAddress = (value) => {
        setPermAddGuardAdd(!value);
        if (permAddGuardAdd) {
            setpermanantAddress("");
        }
        else {
            setpermanantAddress(guardianAddress);
        }
    }

   
    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();

        formData.append('id', id);
        formData.append('reg_no', regNo);
        formData.append('rollNo', rollNo);
        formData.append('class_id', classId);

        formData.append('section', section);
        formData.append('name', name);
        formData.append('lastName', lastName);

        formData.append('gender', gender);
        formData.append('dob', dob);
        formData.append('category', category);

        formData.append('religion', religion);
        formData.append('caste', caste);
        formData.append('contact_no', contactNo);

        formData.append('email', email);
        formData.append('doa', doa);
        formData.append('studentPhoto', studentPhoto);

        formData.append('studentAdhaarNo', studentAdhaarNo);
        formData.append('studentAdhaarPhoto', studentAdhaarPhoto);
        formData.append('birthNo', birthNo);
        formData.append('birthPhoto', birthPhoto);

        formData.append('bloodGroup', bloodGroup);
        formData.append('houseId', houseId);
        formData.append('height', height);

        formData.append('weight', weight);
        formData.append('dom', dom);
        formData.append('father_name', fatherName);

        formData.append('fatherContactNo', fatherContactNo);
        formData.append('fatherOccupation', fatherOccupation);
        formData.append('fatherPhoto', fatherPhoto);
        formData.append('motherName', motherName);

        formData.append('motherContactNo', motherContactNo);
        formData.append('motherOccupation', motherOccupation);
        formData.append('motherPhoto', motherPhoto);
        formData.append('guardianName', guardianName);

        formData.append('guardianRelation', guardianRelation);
        formData.append('guardianEmail', guardianEmail);
        formData.append('guardianPhoto', guardianPhoto);
        formData.append('guardianContactNo', guardianContactNo);

        formData.append('guardianOccupation', guardianOccupation);
        formData.append('guardianAddress', guardianAddress);
        formData.append('currentAddress', currentAddress);

        formData.append('permanantAddress', permanantAddress);
       
        formData.append('fatherAdhaarNo', fatherAdhaarNo);

        formData.append('motherAdhaarNo', motherAdhaarNo);
        formData.append('school_id', location.state.school_id);
        formData.append('session', location.state.session);

        fetch(api_url + "updateStudent", {
            method: 'POST',
            body: formData
        }).then((response) => response.json())
            .then(resp => {
                setLoading(false);
                if (resp.status == 'OK') {
                    toast.success('Updated successfully', {
                        position: "top-center",
                    });

                    setErrors([]);


                    setRegNo("");
                    setRollNo("");
                    setClassId("");

                    setSection("");
                    setName("");
                    setLastName("");
                    setGender("");

                    setDob("");
                    setCategory("");
                    setReligion("");

                    setCaste("");
                    setContactNo("");
                    setEmail("");

                    setDoa(crDate);
                    setStudentPhoto();
                    document.getElementById('studentPhoto').value = null;

                    setStudentAdhaarNo("");
                    setStudentAdhaarPhoto();
                    document.getElementById('studentAdhaarPhoto').value = null;

                    setBirthNo("");
                    setBirthPhoto();
                    document.getElementById('birthPhoto').value = null;


                    setBloodGroup("");
                    setHouseId("");

                    setHeight("");
                    setWeight("");
                    setDom(crDate);

                    setFatherName("");
                    setFatherContactNo("");
                    setFatherOccupation("");
                    setFatherPhoto();
                    document.getElementById('fatherPhoto').value = null;

                    setMotherName("");
                    setMotherContactNo("");
                    setMotherOccupation("");
                    setMotherPhoto();
                    document.getElementById('motherPhoto').value = null;

                    setGuardian("");

                    setGuardianName("");
                    setGuardianRelation("");
                    setGuardianEmail("");
                    setGuardianPhoto();
                    document.getElementById('guardianPhoto').value = null;
                    setGuardianContactNo("");
                    setGuardianOccupation("");
                    setGuardianAddress("");

                    setCurrentAddGuardAdd(false);
                    setPermAddGuardAdd(false);

                    setCurrentAddress("");
                    setpermanantAddress("");


                   
                    setFatherAdhaarNo("");
                    setMotherAdhaarNo("");

                    navigate('/ViewStudents');





                }
                else
                    if (resp.status == 'DUPLICATE') {
                        toast.error('Cannot update try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                    else
                        if (resp.status == 'V_ERROR') {
                            toast.error('Cannot update try again', {
                                position: "top-center",
                            });
                            setErrors(resp.data);
                        }
                        else {
                            toast.error('Cannot update try again', {
                                position: "top-center",
                            });
                            setErrors(resp.message);
                        }
        
            });
    }
    return (
        <div>
            <Header></Header>
            <SideMenu></SideMenu>

            <div className="content-wrapper">

            <ToastContainer />

                <section className="content-header">
                    <div className="header-icon">
                        <i className="pe-7s-note2" />
                    </div>
                    <div className="header-title">
                        <h1>Students</h1>
                        <small>Edit Student</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        {/* Form controls */}
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                
                               
                                <div className="panel-body" >
                                    <form className="col-sm-12" onSubmit={onSubmit}>

                                        <div className="">
                                            <div className="col-sm-4 form-group">
                                                <label>Reg No<span className='required_field'>*</span></label>
                                                <input type="text" className="form-control" value={regNo} placeholder="Enter Reg No" readOnly required autoFocus onChange={(event) => { setRegNo(event.target.value) }} />
                                                <p className='errorMessage'>{errors.regNo}</p>
                                            </div>

                                            <div className="col-sm-4 form-group">
                                                <label>Roll No</label>
                                                <input type="text" className="form-control" value={rollNo} placeholder="Enter Roll No" onChange={(event) => { setRollNo(event.target.value) }} />
                                                <p className='errorMessage'>{errors.rollNo}</p>
                                            </div>

                                            <div className="col-sm-4 form-group">
                                                <label>Class<span className='required_field'>*</span></label>
                                                <select className="form-control" id="exampleSelect1" value={classId} required name="class" onChange={(event) => { setClassId(event.target.value) }}>
                                                    <option value="">Select</option>
                                                    {
                                                        classes.length ?
                                                            classes.map((value) => {
                                                                return (<option key={value.id} value={value.id}>{value.name}</option>);
                                                            })
                                                            :
                                                            <option value="">Not Record Found!</option>
                                                    }
                                                </select>
                                                <p className='errorMessage'>{errors.classId}</p>
                                            </div>

                                            <div className="col-sm-4 form-group">
                                                <label>Section</label>
                                                <select className="form-control" id="exampleSelect1" value={section} onChange={(event) => { setSection(event.target.value) }}>
                                                    <option value="">Select</option>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                    <option value="D">D</option>
                                                </select>
                                                <p className='errorMessage'>{errors.section}</p>
                                            </div>



                                            <div className="col-sm-4 form-group">
                                                <label>First Name<span className='required_field'>*</span></label>
                                                <input type="text" className="form-control" value={name} placeholder="Enter First Name" required onChange={(event) => { setName(event.target.value) }} />
                                                <p className='errorMessage'>{errors.name}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control" value={lastName} placeholder="Enter Last Name" onChange={(event) => { setLastName(event.target.value) }} />
                                                <p className='errorMessage'>{errors.lastName}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Gender<span className='required_field'>*</span></label>
                                                <select className="form-control" id="exampleSelect1" value={gender} required onChange={(event) => { setGender(event.target.value) }} >
                                                    <option value="">Select</option>
                                                    <option value="MALE">Male</option>
                                                    <option value="FEMALE">Female</option>
                                                </select>
                                                <p className='errorMessage'>{errors.gender}</p>
                                            </div>

                                            <div className="col-sm-4 form-group">
                                                <label>Date Of Birth<span className='required_field'>*</span></label>
                                                <input type="date" className="form-control" value={dob} required onChange={(event) => { setDob(event.target.value) }} />
                                                <p className='errorMessage'>{errors.dob}</p>
                                            </div>



                                            <div className="col-sm-4 form-group">
                                                <label>Category</label>
                                                <select className="form-control" value={category} onChange={(event) => { setCategory(event.target.value) }} >
                                                    <option value="">Select</option>
                                                    <option value="General">General</option>
                                                    <option value="OBC">OBC</option>
                                                    <option value="SC">SC</option>
                                                    <option value="ST">ST</option>
                                                    <option value="Physically Disabled">Physically Disabled</option>
                                                </select>
                                                <p className='errorMessage'>{errors.category}</p>
                                            </div>

                                            <div className="col-sm-4 form-group">
                                                <label>Caste</label>
                                                <input type="text" className="form-control" value={caste} placeholder="Enter Caste" onChange={(event) => { setCaste(event.target.value) }} />
                                                <p className='errorMessage'>{errors.caste}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Religion</label>
                                                <select className="form-control" value={religion} onChange={(event) => { setReligion(event.target.value) }} >
                                                    <option value="">Select</option>
                                                    <option value="Hinduism">Hinduism</option>
                                                    <option value="Islam">Islam</option>
                                                    <option value="Sikhism">Sikhism</option>
                                                    <option value="Christianity">Christianity</option>
                                                    <option value="Buddhism">Buddhism</option>
                                                    <option value="Judaism">Judaism</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                <p className='errorMessage'>{errors.religion}</p>
                                            </div>


                                           

                                            <div className="col-sm-4 form-group">
                                                <label>Contact No<span className='required_field'>*</span></label>
                                                <input type="number" className="form-control" value={contactNo} required placeholder="Enter Contact No" onChange={(event) => { setContactNo(event.target.value) }} />
                                                <p className='errorMessage'>{errors.contactNo}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Email</label>
                                                <input type="email" className="form-control" value={email} placeholder="Enter Email" onChange={(event) => { setEmail(event.target.value) }} />
                                                <p className='errorMessage'>{errors.email}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Admission Date<span className='required_field'>*</span></label>
                                                <input type="date" className="form-control" value={doa} required onChange={(event) => { setDoa(event.target.value) }} />
                                                <p className='errorMessage'>{errors.doa}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Student Photo(Max Size 100kb)</label>

                                                <div style={{display:'flex'}}>
                                                {studentPhoto ? (
                                                    <div>
                                                    <img src={studentPhoto} className='photoThumb' />
                                                    </div>
                                                ) : null}

                                                <input type="file" id='studentPhoto' className="form-control"
                                                    onChange={(e) => setStudentPhoto(e.target.files[0])}
                                                    placeholder="file" />
                                                </div>
                                                <p className='errorMessage'>{errors.studentPhoto}</p>
                                            </div>




                                            <div className="col-sm-4 form-group">
                                                <label>Student Adhaar No</label>
                                                <input type="text" className="form-control" value={studentAdhaarNo} placeholder="Enter Student Adhaar" onChange={(event) => { setStudentAdhaarNo(event.target.value) }} />
                                                <p className='errorMessage'>{errors.studentAdhaarNo}</p>
                                            </div>


                                            
                                            <div className="col-sm-4 form-group">
                                                <label>Student Adhaar Photo(Max Size 100kb)</label>
                                                <div style={{display:'flex'}}>
                                                {studentAdhaarPhoto ? (
                                                    <div>
                                                    <img src={studentAdhaarPhoto} className='photoThumb' />
                                                    </div>
                                                ) : null}

                                                <input type="file" id='studentAdhaarPhoto' className="form-control"
                                                    onChange={(e) => setStudentAdhaarPhoto(e.target.files[0])}
                                                    placeholder="file" />
                                                    </div>
                                                <p className='errorMessage'>{errors.studentAdhaarPhoto}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Birth Certificate No</label>
                                                <input type="text" className="form-control" value={birthNo} placeholder="Enter Birth Certificate No" onChange={(event) => { setBirthNo(event.target.value) }} />
                                                <p className='errorMessage'>{errors.birthNo}</p>
                                            </div>


                                            
                                            <div className="col-sm-4 form-group">
                                                <label>Birth Certificate Photo(Max Size 100kb)</label>

                                                <div style={{display:'flex'}}>
                                                {birthPhoto ? (
                                                    <div>
                                                    <img src={birthPhoto} className='photoThumb' />
                                                    </div>
                                                ) : null}

                                                <input type="file" id='birthPhoto' className="form-control"
                                                    onChange={(e) => setBirthPhoto(e.target.files[0])}
                                                    placeholder="file" />
                                                    </div>
                                                <p className='errorMessage'>{errors.birthPhoto}</p>
                                            </div>




                                            <div className="col-sm-4 form-group">
                                                <label>Blood Group</label>
                                                <select className="form-control" value={bloodGroup} onChange={(event) => { setBloodGroup(event.target.value) }} >
                                                    <option value="">Select</option>
                                                    <option value="O+">O+</option>
                                                    <option value="A+">A+</option>
                                                    <option value="B+">B+</option>
                                                    <option value="AB+">AB+</option>
                                                    <option value="O-">O-</option>
                                                    <option value="A-">A-</option>
                                                    <option value="B-">B-</option>
                                                    <option value="AB-">AB-</option>
                                                </select>
                                                <p className='errorMessage'>{errors.bloodGroup}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>House</label>
                                                <select className="form-control" value={houseId} onChange={(event) => { setHouseId(event.target.value) }} >
                                                    <option value="">Select</option>
                                                    {
                                                        houses.length ?
                                                            houses.map((value) => {
                                                                return (<option key={value.id} value={value.id}>{value.name}</option>);
                                                            })
                                                            :
                                                            <option value="">Not Record Found!</option>
                                                    }
                                                </select>
                                                <p className='errorMessage'>{errors.houseId}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Height</label>
                                                <input type="text" className="form-control" value={height} placeholder="Enter Height" onChange={(event) => { setHeight(event.target.value) }} />
                                                <p className='errorMessage'>{errors.height}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Weight</label>
                                                <input type="text" className="form-control" value={weight} placeholder="Enter Weight" onChange={(event) => { setWeight(event.target.value) }} />
                                                <p className='errorMessage'>{errors.weight}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Measurement Date</label>
                                                <input type="date" className="form-control" value={dom} onChange={(event) => { setDom(event.target.value) }} />
                                                <p className='errorMessage'>{errors.dom}</p>
                                            </div>





                                            <div className="row sectionDidiverForm">
                                                <div className="col-sm-12" >
                                                    <div className="panel-bd">
                                                        <div className="panel-heading">
                                                            <h4>Father Detail</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                            <div className="col-sm-4 form-group">
                                                <label>Father Name<span className='required_field'>*</span></label>
                                                <input type="text" className="form-control" value={fatherName} placeholder="Enter Father Name" required onChange={(event) => { setFatherName(event.target.value) }} />
                                                <p className='errorMessage'>{errors.fatherName}</p>
                                            </div>

                                            <div className="col-sm-4 form-group">
                                                <label>Father Phone</label>
                                                <input type="text" className="form-control" value={fatherContactNo} placeholder="Enter Father Phone" onChange={(event) => { setFatherContactNo(event.target.value) }} />
                                                <p className='errorMessage'>{errors.fatherContactNo}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Father Occupation</label>
                                                <input type="text" className="form-control" value={fatherOccupation} placeholder="Enter Father Occupation" onChange={(event) => { setFatherOccupation(event.target.value) }} />
                                                <p className='errorMessage'>{errors.fatherOccupation}</p>
                                            </div>

                                            <div className="col-sm-4 form-group">                                               
                                                <label>Father Photo(Max Size 100kb)</label>

                                                <div style={{display:'flex'}}>
                                                {fatherPhoto ? (
                                                    <div>
                                                    <img src={fatherPhoto} className='photoThumb' />
                                                    </div>
                                                ) : null}
                                                <input type="file" id='fatherPhoto' className="form-control"
                                                    onChange={(e) => setFatherPhoto(e.target.files[0])}
                                                    placeholder="file" />
                                                    </div>
                                                <p className='errorMessage'>{errors.fatherPhoto}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Father Adhaar No</label>
                                                <input type="text" className="form-control" value={fatherAdhaarNo} placeholder="Enter Father Adhaar" onChange={(event) => { setFatherAdhaarNo(event.target.value) }} />
                                                <p className='errorMessage'>{errors.fatherAdhaarNo}</p>
                                            </div>


                                            <div className="row sectionDidiverForm">
                                                <div className="col-sm-12" >
                                                    <div className="panel-bd">
                                                        <div className="panel-heading">
                                                            <h4>Mother Detail</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Mother Name</label>
                                                <input type="text" className="form-control" value={motherName} placeholder="Enter Mother Name" onChange={(event) => { setMotherName(event.target.value) }} />
                                                <p className='errorMessage'>{errors.motherName}</p>
                                            </div>

                                            <div className="col-sm-4 form-group">
                                                <label>Mother Phone</label>
                                                <input type="text" className="form-control" value={motherContactNo} placeholder="Enter Mother Phone" onChange={(event) => { setMotherContactNo(event.target.value) }} />
                                                <p className='errorMessage'>{errors.motherContactNo}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Mother Occupation</label>
                                                <input type="text" className="form-control" value={motherOccupation} placeholder="Enter Mother Occupation" onChange={(event) => { setMotherOccupation(event.target.value) }} />
                                                <p className='errorMessage'>{errors.motherOccupation}</p>
                                            </div>

                                             <div className="col-sm-4 form-group">                                               
                                                <label>Mother Photo(Max Size 100kb)</label>

                                                <div style={{display:'flex'}}>
                                                {motherPhoto ? (
                                                    <div>
                                                    <img src={motherPhoto} className='photoThumb' />
                                                    </div>
                                                ) : null}
                                                <input type="file" id='motherPhoto' className="form-control"
                                                    onChange={(e) => setMotherPhoto(e.target.files[0])}
                                                    placeholder="file" />
                                                    </div>
                                                <p className='errorMessage'>{errors.motherPhoto}</p>
                                            </div>


                                            <div className="col-sm-4 form-group">
                                                <label>Mother Adhaar No</label>
                                                <input type="text" className="form-control" value={motherAdhaarNo} placeholder="Enter Mother Adhaar" onChange={(event) => { setMotherAdhaarNo(event.target.value) }} />
                                                <p className='errorMessage'>{errors.motherAdhaarNo}</p>
                                            </div>



                                            <div className='col-sm-12 form-group'>
                                                <div className="form-check">
                                                    <label>If Guardian Is</label><br />
                                                    <label className="radio-inline">
                                                        <input type="radio" name="status" value={guardian} onChange={() => { setGuardianType("Father") }} />Father
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input type="radio" name="status" value={guardian} onChange={() => { setGuardianType("Mother") }} />Mother
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input type="radio" name="status" value={guardian} onChange={() => { setGuardianType("Other") }} />Other
                                                    </label>
                                                </div>
                                            </div>


                                            <div className="col-sm-3 form-group">
                                                <label>Guardian Name</label>
                                                <input type="text" className="form-control" value={guardianName} placeholder="Enter Guardian Name" onChange={(event) => { setGuardianName(event.target.value) }} />
                                                <p className='errorMessage'>{errors.guardianName}</p>
                                            </div>


                                            <div className="col-sm-3 form-group">
                                                <label>Guardian Relation</label>
                                                <input type="text" className="form-control" value={guardianRelation} placeholder="Enter Guardian Relation" onChange={(event) => { setGuardianRelation(event.target.value) }} />
                                                <p className='errorMessage'>{errors.guardianRelation}</p>
                                            </div>


                                            <div className="col-sm-3 form-group">
                                                <label>Guardian Email</label>
                                                <input type="email" className="form-control" value={guardianEmail} placeholder="Enter Guardian Email" onChange={(event) => { setGuardianEmail(event.target.value) }} />
                                                <p className='errorMessage'>{errors.guardianEmail}</p>
                                            </div>                                    

                                            <div className="col-sm-3 form-group">                                               
                                                <label>Guardian Photo(Max Size 100kb)</label>

                                                <div style={{display:'flex'}}>
                                                {guardianPhoto ? (
                                                    <div>
                                                    <img src={guardianPhoto} className='photoThumb' />
                                                    </div>
                                                ) : null}

                                                <input type="file" id='guardianPhoto' className="form-control"
                                                    onChange={(e) => setGuardianPhoto(e.target.files[0])}
                                                    placeholder="file" />
                                                    </div>
                                                <p className='errorMessage'>{errors.guardianPhoto}</p>
                                            </div>

                                            <div className="col-sm-3 form-group">
                                                <label>Guardian Phone</label>
                                                <input type="text" className="form-control" value={guardianContactNo} placeholder="Enter Guardian Contact No" onChange={(event) => { setGuardianContactNo(event.target.value) }} />
                                                <p className='errorMessage'>{errors.guardianContactNo}</p>
                                            </div>

                                            <div className="col-sm-3 form-group">
                                                <label>Guardian Occupation</label>
                                                <input type="text" className="form-control" value={guardianOccupation} placeholder="Enter Guardian Occupation" onChange={(event) => { setGuardianOccupation(event.target.value) }} />
                                                <p className='errorMessage'>{errors.guardianOccupation}</p>
                                            </div>

                                            <div className="col-sm-3 form-group">
                                                <label>Guardian Address</label>
                                                <input type="text" className="form-control" value={guardianAddress} placeholder="Enter Guardian Address" onChange={(event) => { setGuardianAddress(event.target.value) }} />
                                                <p className='errorMessage'>{errors.guardianAddress}</p>
                                            </div>




                                            <div className="row sectionDidiverForm">
                                                <div className="col-sm-12" >
                                                    <div className="panel-bd">
                                                        <div className="panel-heading">
                                                            <h4>Student Address Detail</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-sm-6 form-group">
                                                <div>
                                                    <input type="checkbox" value={currentAddGuardAdd} onClick={(event) => { makeCurrentAddressAsGuardianAddress(currentAddGuardAdd) }} />
                                                    <label>
                                                        &nbsp;&nbsp;If Guardian Address Is Current Address
                                                    </label>
                                                </div>
                                                <label>Current Address</label>
                                                <input type="text" className="form-control" value={currentAddress} placeholder="Enter Current Address" onChange={(event) => { setCurrentAddress(event.target.value) }} />
                                                <p className='errorMessage'>{errors.currentAddress}</p>
                                            </div>


                                            <div className="col-sm-6 form-group">
                                                <div>
                                                    <input type="checkbox" value={permAddGuardAdd} onClick={(event) => { makePermAddressAsGuardianAddress(permAddGuardAdd) }} />
                                                    <label>
                                                        &nbsp;&nbsp;If Guardian Address Is Permanant Address
                                                    </label>
                                                </div>
                                                <label>Permanant Address</label>
                                                <input type="text" className="form-control" value={permanantAddress} placeholder="Enter Permanant Address" onChange={(event) => { setpermanantAddress(event.target.value) }} />
                                                <p className='errorMessage'>{errors.permanantAddress}</p>
                                            </div>

                                  


                                          

                                        </div>
                                        <div className="col-sm-12 reset-button">
                                            {
                                                !loading ? (
                                                    <button type='submit' className="btn btn-success">Save Student</button>)
                                                    :
                                                    (<div className='loader'>
                                                        <Circles
                                                            height="60"
                                                            width="60"
                                                            color="#4fa94d"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={loading}
                                                        />
                                                    </div>)
                                            }

                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> {/* /.content */}




            </div> {/* /.content-wrapper */}

        </div>

    )
}

export default UpdateStudent;