import React,{useState} from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';


function UpdateClass() 
{  
    const location = useLocation();    
    const id = location.state.id;

    const navigate = useNavigate();

    const [className,setClassName] = useState(location.state.name);
    const [errors, setErrors] = useState([]);    
    const [loading, setLoading] = useState(false);   

     const classNameChangeHandler = (event) =>{
        setClassName(event.target.value);
  };

  const onSubmit = (event)=>
  {
    event.preventDefault();
    setLoading(true);
 
  const userData = {
        name: className,
        id: id,
        school_id: location.state.school_id,
        session: location.state.session
    }

    Axios.post(api_url+'updateClass', qs.stringify(userData))
    .then(resp => {
        setLoading(false);
        if(resp.data.status=='OK')
        {
             setClassName("");
             toast.success('Updated successfully', {
                position: "top-center",
                });
             navigate('/AddClass');
        }
        else
        if (resp.data.status == 'DUPLICATE')
         {
            toast.error('Cannot create try again', {
                position: "top-center",
            });
            setErrors(resp.data);
        }
        else
        if(resp.data.status=='V_ERROR')
        {
            setErrors(resp.data);
        }
        else
        {
            setErrors(resp.data.message);
        }       
   });

   


};

  return (
    <div>
        <Header></Header>
             <SideMenu></SideMenu>
           
         <div className="content-wrapper" style={{minHeight:'100vh'}}>
        
        <section className="content-header">
        <div className="header-icon">
            <i className="pe-7s-note2" />
        </div>
        <div className="header-title">
            <h1>Classes</h1>
            <small>Edit Class</small>
            <ol className="breadcrumb hidden-xs">
            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
            <li className="active">Dashboard</li>
            </ol>
        </div>
        </section>
        {/* Main content */}
        <section className="content">
        <div className="row">
            {/* Form controls */}
            <div className="col-sm-12">
            <div className="panel panel-bd lobidrag">
 
           
                <div className="panel-body">
                <form className="col-sm-12" onSubmit={onSubmit}>
                    <div className="col-sm-6 form-group">
                    <label>Class Name</label>
                    <input type="text" className="form-control" placeholder="Enter Class Name" name="className" required value={className}  onChange={classNameChangeHandler}  autoFocus/>
                    <p className='errorMessage'>{errors.name}</p>
                    </div>                   

                    <div className="col-sm-12">  
                            {
                                !loading ? (                           
                                <button type='submit' className="btn btn-success">Update Class</button>) 
                                : 
                                (<div  className='loader'>
                                   <Circles
                                    height="60"
                                    width="60"
                                    color="#4fa94d"
                                    ariaLabel="circles-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={loading}
                                    />
                                    </div>)
                            }
                          </div>


                </form>
                </div>
            </div>
            </div>
        </div>
        </section> {/* /.content */}











      
</div> {/* /.content-wrapper */}
    </div>
  )
}

export default UpdateClass