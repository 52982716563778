import React,{useState, useEffect} from 'react'
import './FeeReceipt.css'
import { useLocation, useParams} from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PrintFeeReceipt = () => {

    const location = useLocation();    
    //const scode = location.state.scode;
    const { scode, school_id } = useParams();
    //const scode = '1a67911515bd42a6a20e27585ed8a053';
    
    const [schoolName, setSchoolName] = useState("");
    const [studentDetails, setStudentDetails] = useState([]);
    const [receiptDetails, setReceiptDetails] = useState([]);
    const [feeDetails, setFeeDetails] = useState([]);
    const [randDep, setRandDep] = useState(0);

    const [fineDetails, setFineDetails] = useState([]);


    useEffect(() => {
        const userData = {
            scode: scode,
            school_id:school_id,
        }
        Axios.post(api_url + 'getPrintReceiptDetails', qs.stringify(userData))
            .then((response) => {               
                setSchoolName(response.data.schoolName);
                setStudentDetails(response.data.studentDetails);
                setReceiptDetails(response.data.receiptDetails);
                setFeeDetails(response.data.feeDetails);
                setFineDetails(response.data.fineDetails);
            })
    }, [randDep]);


    return (
        <div style={{ backgroundColor: '#fff', minHeight: '100vh' }}>








            <div className="super" style={{ borderRight: '1px dashed #CCC' }}>
                <div className="main">
                    <div className="header">
                        <h1 style={{ fontSize: '16px' }}>{schoolName}</h1>
                        <h1 style={{ fontSize: '16px', textTransform: 'none', marginTop: '-20' }}>Fee Receipt</h1>
                    </div>



                    <br />
                    <div className="container" style={{ fontFamily: 'sans-serif' }}>
                        <p className="pleft">Name:</p> <p className="pright">{studentDetails[0]?.name}</p><div className="clear"></div>

                        <p className="pleft">Father Name:</p> <p className="pright">Mr {studentDetails[0]?.father_name}</p><div className="clear"></div>

                        <p className="pleft">Reg No:</p>
                        <p className="pright" style={{ width: '50px' }}>{studentDetails[0]?.reg_no}</p>

                        <p className="pleft" style={{ width: '40px' }}>Date:</p>
                        <p className="pright">{Moment(receiptDetails[0]?.receipt_date).format("DD-MM-YYYY")}</p>
                        <div className="clear"></div>

                        <p className="pleft">Receipt No:</p>
                        <p className="pright" style={{ width: '50px' }}>{receiptDetails[0]?.receipt_no}</p>


                        <p className="pleft" style={{ width: '40px' }}>Time:</p>
                        <p className="pright">{Moment(receiptDetails[0]?.receipt_date).format("h:m:s")}</p>
                        <div className="clear"></div>

                        <p className="pleft">&nbsp;</p>
                        <p className="pright">&nbsp;</p>
                        <p className="pleft" style={{ marginLeft: '58px', width: '40px' }}>Class:</p>
                        <p className="pright">&nbsp;{studentDetails[0]?.class_name}</p>
                        <div className="clear"></div>

                        <table border="0" cellSpacing="0" cellPadding="0" className="fee_table">
                            <tr className="tr1">
                                <td ><label>Fee Type</label></td>
                                <td><label>Fee Name</label></td>
                                <td><label>Amount</label></td>
                            </tr>

                            {
                                                            feeDetails.length ?
                                                            feeDetails.map((value, key) => {

                                                                    return (
                            <tr key={key}>
                                <td>{value.fee_name}</td>
                                <td>{value.fee_months}</td>
                                <td>{value.amount}</td>
                            </tr>);
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan='3'>No record found!</td>
                                                                </tr>
                                                        }

                           
                            <tr>
                                <td colSpan="3"><label>Total Fee Calculated (RS)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {receiptDetails[0]?.amount}</label></td>
                            </tr>
                            <tr>
                                <td colSpan="3"><label>Total Consession   (RS)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {receiptDetails[0]?.concession}</label></td>
                            </tr>





                            <tr className="tr1">
                                <td style={{ height: '20px', borderBottom: 'none' }}><label>Total Fees Paid&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</label></td>
                                <td style={{ borderBottom: 'none' }}><label>&nbsp;</label></td>
                                <td style={{ borderBottom: 'none' }}><label>{Number(receiptDetails[0]?.amount) + Number(receiptDetails[0]?.concession)}</label></td>
                            </tr>

                            <tr className="tr1">
                                <td style={{ height: '16px', borderTop: 'none' }}><label>Total Dues Till Month&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</label></td>
                                <td style={{ borderTop: 'none' }}><label>&nbsp;</label></td>
                                <td style={{ borderTop: 'none' }}><label>NA</label></td>
                            </tr>

                        </table>
                        <p style={{ paddingLeft: '5px', marginTop: '4px' }}>num in words</p>


                        <ul style={{ paddingLeft: '5px', marginTop: '1px', textTransform: 'capitalize' }}>

                            <li>Fine After {fineDetails?.fineAfterDays}date will be charged Rs.{fineDetails?.fineAmount} Per day</li>



                            <li>Vehicle Info:- NA</li>


                            <li>For Any Query Call 9927929850</li>
                        </ul>



                        <div style={{ marginTop: '70px' }}>
                            <label style={{ float: 'left' }}>Parent's Copy</label>
                            <label style={{ float: 'right' }}><b>Signature</b></label>
                        </div>
                    </div>

                </div>
            </div>



            <div className="super" style={{ marginLeft:'-20px' }}>
                <div className="main">
                    <div className="header">
                        <h1 style={{ fontSize: '16px' }}>{schoolName}</h1>
                        <h1 style={{ fontSize: '16px', textTransform: 'none', marginTop: '-20' }}>Fee Receipt</h1>
                    </div>



                    <br />
                    <div className="container" style={{ fontFamily: 'sans-serif' }}>
                        <p className="pleft">Name:</p> <p className="pright">{studentDetails[0]?.name}</p><div className="clear"></div>

                        <p className="pleft">Father Name:</p> <p className="pright">Mr {studentDetails[0]?.father_name}</p><div className="clear"></div>

                        <p className="pleft">Reg No:</p>
                        <p className="pright" style={{ width: '50px' }}>{studentDetails[0]?.reg_no}</p>

                        <p className="pleft" style={{ width: '40px' }}>Date:</p>
                        <p className="pright">{Moment(receiptDetails[0]?.receipt_date).format("DD-MM-YYYY")}</p>
                        <div className="clear"></div>

                        <p className="pleft">Receipt No:</p>
                        <p className="pright" style={{ width: '50px' }}>{receiptDetails[0]?.receipt_no}</p>


                        <p className="pleft" style={{ width: '40px' }}>Time:</p>
                        <p className="pright">{Moment(receiptDetails[0]?.receipt_date).format("h:m:s")}</p>
                        <div className="clear"></div>

                        <p className="pleft">&nbsp;</p>
                        <p className="pright">&nbsp;</p>
                        <p className="pleft" style={{ marginLeft: '58px', width: '40px' }}>Class:</p>
                        <p className="pright">&nbsp;{studentDetails[0]?.class_name}</p>
                        <div className="clear"></div>

                        <table border="0" cellSpacing="0" cellPadding="0" className="fee_table">
                            <tr className="tr1">
                                <td ><label>Fee Type</label></td>
                                <td><label>Fee Name</label></td>
                                <td><label>Amount</label></td>
                            </tr>

                            {
                                                            feeDetails.length ?
                                                            feeDetails.map((value, key) => {

                                                                    return (
                            <tr key={key}>
                                <td>{value.fee_name}</td>
                                <td>{value.fee_months}</td>
                                <td>{value.amount}</td>
                            </tr>);
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan='3'>No record found!</td>
                                                                </tr>
                                                        }

                           
                            <tr>
                                <td colSpan="3"><label>Total Fee Calculated (RS)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {receiptDetails[0]?.amount}</label></td>
                            </tr>
                            <tr>
                                <td colSpan="3"><label>Total Consession   (RS)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {receiptDetails[0]?.concession}</label></td>
                            </tr>





                            <tr className="tr1">
                                <td style={{ height: '20px', borderBottom: 'none' }}><label>Total Fees Paid&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</label></td>
                                <td style={{ borderBottom: 'none' }}><label>&nbsp;</label></td>
                                <td style={{ borderBottom: 'none' }}><label>{Number(receiptDetails[0]?.amount) + Number(receiptDetails[0]?.concession)}</label></td>
                            </tr>

                            <tr className="tr1">
                                <td style={{ height: '16px', borderTop: 'none' }}><label>Total Dues Till Month&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</label></td>
                                <td style={{ borderTop: 'none' }}><label>&nbsp;</label></td>
                                <td style={{ borderTop: 'none' }}><label>NA</label></td>
                            </tr>

                        </table>
                        <p style={{ paddingLeft: '5px', marginTop: '4px' }}>num in words</p>


                        <ul style={{ paddingLeft: '5px', marginTop: '1px', textTransform: 'capitalize' }}>

                        <li>Fine After {fineDetails?.fineAfterDays}date will be charged Rs.{fineDetails?.fineAmount} Per day</li>




                            <li>Vehicle Info:- NA</li>


                            <li>For Any Query Call 9927929850</li>
                        </ul>



                        <div style={{ marginTop: '70px' }}>
                            <label style={{ float: 'left' }}>School's Copy</label>
                            <label style={{ float: 'right' }}><b>Signature</b></label>
                        </div>
                    </div>

                </div>
            </div>













        </div>
    )
}

export default PrintFeeReceipt