import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';

const ViewReceipts = () => {

    const { schoolID, schoolSession } = useContext(SchoolContext);

    const [classes, setClasses] = useState([]);
    const [classId, setClassId] = useState("");

    const [randDep, setRandDep] = useState(0);
    const [receipts, setReceipts] = useState([]);
    const [receiptsAll, setReceiptsAll] = useState([]);
    const [students, setStudents] = useState([]);
    const [studentsAll, setStudentsAll] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }

        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getFeeReceipts', qs.stringify(userData))
            .then((response) => {
                //console.log(response.data);
                setReceipts(response.data.data);
                setReceiptsAll(response.data.data);
                setLoading(false);
            })

            getClasses();
    }, [randDep]);




    const handleOnSearchInput = async text => {
        setSearchQuery(text);
        if (!text.trim()) {
            setSearchQuery('');
            setClassId('');
            setRandDep(Math.random());
        }

        const filteredData = receiptsAll.filter(student => {
            if ((student.reg_no.toLowerCase().includes(text.toLowerCase())) || (student.student_name.toLowerCase().includes(text.toLowerCase())) || (student.contact_no.toLowerCase().includes(text.toLowerCase()))) {
                return student;
            }
        });


        if (filteredData.length) {
            setReceipts([...filteredData]);
        }
        else {
            setReceipts([]);
        }
    };


     
    const getClasses = () => {
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }

        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getClasses', qs.stringify(userData))
            .then((response) => {
                setClasses(response.data.data)
            })
    }


    const classChangeHandler = (event) => {
        let text = event.target.value;
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }


        const filteredData = receiptsAll.filter(student => {
            if ((student.class_name == text)) {
                return student;
            }
        });


        if (filteredData.length) {
            setReceipts([...filteredData]);
        }
        else {
            setReceipts([]);
        }

        setClassId(text);

    };

    const deleteFeeReceipt = (scode) => {
        const delData = {
            scode: scode
        }
       
        Axios.put(api_url + 'deleteFeeReceipt/', qs.stringify(delData))
            .then((resp) => {
                if (resp.data.status == 'OK') 
                {
                    toast.success('Deleted Successfully', {
                        position: "top-center",
                    });

                    setRandDep(Math.random());
                }
            })
    }

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Fee Receipts</h1>
                        <small>Fee Receipts list</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-heading">
                                    <div className="btn-group">
                                        <Link to="/FeesPayment" className="btn btn-success"><i className="fa fa-plus"></i>
                                            &nbsp;Fee Payment
                                        </Link>
                                    </div>
                                </div>




                                <div className="panel-body">

                                    <div className="row">
                                        <div className="panel-header">


                                            <div className="col-md-12 col-sm-6 col-xs-12">
                                                <div className="dataTables_length">
                                                   

                                                <div className="row">


<div className='col-lg-3 col-md-4 col-sm-6'>
    <input type="text" className="form-control" placeholder="search by reg no, name, contact no or class" onChange={(event) => { handleOnSearchInput(event.target.value) }} />
</div>

<div className='col-lg-2 col-md-3 col-sm-6'>
    <select className="form-control" value={classId} required name="class" onChange={classChangeHandler} >
        <option value="">Select Class</option>
        {
            classes.length ?
                classes.map((value) => {
                    return (<option key={value.id} value={value.name}>{value.name}</option>);
                })
                :
                <option value="">Not Record Found!</option>
        }
    </select>
</div>





</div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        {
                                            !loading ? (
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Receipt No</th>
                                                            <th>Name</th>
                                                            <th>Reg No</th>

                                                            <th>Contact No</th>
                                                            <th>Class</th>
                                                            <th>Amount</th>

                                                            <th>Concession</th>
                                                            <th>Created At</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            receipts.length ?
                                                                receipts.map((value, key) => {

                                                                    return (<tr key={key}>
                                                                        <td>{value.receipt_no}</td>
                                                                        <td>{value.student_name}</td>
                                                                        <td>{value.reg_no}</td>
                                                                        <td>{value.contact_no}</td>

                                                                        <td>{value.class_name}</td>
                                                                        <td>{value.amount}</td>
                                                                        <td>{value.concession}</td>

                                                                        <td>{Moment(value.receipt_date).format("DD-MM-YYYY, h:m:s")}</td>
                                                                        <td>
                                                                            <Link
                                                                                // to={{ pathname: `/PrintFeeReceipt`,target:'blank' }}                                                
                                                                                // state={{
                                                                                //     scode: value.scode,  
                                                                                //     school_id: value.school_id,                                                
                                                                                // }}

                                                                                to={{
                                                                                    //pathname: `/printfeereceipt/${value.scode}`
                                                                                    //pathname: `/feereceipt/print/${value.scode}`, 
                                                                                    pathname: `/PrintReceipt/${value.scode}/${value.school_id}`
                                                                                }}
                                                                                target='_blank'
                                                                            >
                                                                                <button type="button" title='Print' className="btn btn1 btn-info btn-xs" data-toggle="modal" data-target="#ordine"><i className="fa fa-print" />
                                                                                </button>
                                                                            </Link>



                                                                            <button type="button" className="btn btn1 btn-danger btn-xs" onClick={() => window.confirm('Are you sure you wish to delete this item?') ? deleteFeeReceipt(value.scode) : ""}><i className="fa fa-trash-o" />
                                                                            </button>



                                                                        </td>
                                                                    </tr>);
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan='9'>No record found!</td>
                                                                </tr>
                                                        }

                                                        <tr>
                                                            <td colSpan="9" className='table_bottom_total'>Total {receipts.length} records found</td>
                                                        </tr>


                                                    </tbody>
                                                </table>)
                                                :
                                                (<div className='loader' style={{ marginLeft: '20%' }}>
                                                    <Circles
                                                        height="60"
                                                        width="60"
                                                        color="#4fa94d"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={loading}
                                                    />
                                                </div>)
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default ViewReceipts