import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';


const AssignFeesToClass = () => {
    const { schoolID, schoolSession } = useContext(SchoolContext);

    const [classes, setClasses] = useState([]);
    const [classId, setClassId] = useState("");
    const [fees, setFees] = useState([]);

    const [randDep, setRandDep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(true);

    const getClasses = () => {
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }

        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getClasses', qs.stringify(userData))
            .then((response) => {
                setClasses(response.data.data)
            })
    }

    //console.log(fees);


    const getFeesData = async () => {
        if (schoolID > 0 && schoolID != null && classId > 0) 
        {
            setLoading1(true);
            try {
                const userData1 = {
                    school_id: schoolID,
                    session: schoolSession,
                    class_id:classId
                }
                await Axios.post(api_url + 'getFeesAndClasses', qs.stringify(userData1))
                    .then(resp => {
                        setLoading1(false);
                        if (resp.data['message'] == 'OK') {
                            //console.log(resp.data.data);
                            setFees(resp.data.data);
                        }
    
                    });
    
            }
            catch (err) {
                console.log(err);
            }
    
        }
       
    }

    useEffect(() => {
        getClasses();
    }, [randDep]);


    useEffect(() => {
        getFeesData();
        //getAttendanceData();
    }, [randDep]);


    const classChangeHandler = (event) => {
        setClassId(event.target.value);
        setRandDep(Math.random);
    };


    const updateCheckStatus = (index) => {
        setFees(
            fees.map((topping, currentIndex) =>
                currentIndex === index
                    ? { ...topping, checked: !topping.checked }
                    : topping
            )
        )
    }

    const updateFeeAmount = (index, amount) => {
        setFees(
            fees.map((topping, currentIndex) =>
                currentIndex === index
                    ? { ...topping, amount: amount }
                    : topping
            )
        )
    }



    const updateFeeToClass = () => {

        setLoading(true);

        if (classId == '') {
            toast.warning('Please select class', {
                position: "top-center",
            });
        }
        else {
            let partialDetails = fees.map((
                { id, checked, amount }) => ({ id, checked, amount }));

            const userData = {
                fees_data: partialDetails,
                class_id: classId,
            }

            //console.log(userData);

            Axios.post(api_url + 'assignFeesToClass', qs.stringify(userData))
                .then(resp => {
                    setLoading(false);
                    if (resp.data.status == 'OK') {
                        // setClassId();
                        toast.success('Updated successfully', {
                            position: "top-center",
                        });
                    }
                    else
                        if (resp.data.status == 'V_ERROR') {
                            toast.error('Cannot add try again', {
                                position: "top-center",
                            });
                        }
                        else {
                            toast.error('Cannot update try again', {
                                position: "top-center",
                            });
                        }

                });
        }
    }

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Assign Fees</h1>
                        <small>Assign Fees To Class</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-body">

                                    <div className="row">
                                        <div className="panel-header">


                                            <div className="col-sm-6 col-xs-12">
                                                <div className="dataTables_length">
                                                    <div className="input-group custom-search-form">



                                                        <span className="input-group-btn">

                                                            <select className="form-control" style={{ width: 200 }} value={classId} required name="class" onChange={classChangeHandler} >
                                                                <option value="">Select</option>
                                                                {
                                                                    classes.length ?
                                                                        classes.map((value) => {
                                                                            return (<option key={value.id} value={value.id}>{value.name}</option>);
                                                                        })
                                                                        :
                                                                        <option value="">Not Record Found!</option>
                                                                }
                                                            </select>




                                                            <button className="btn btn-primary" type="button" >
                                                                <span className="glyphicon glyphicon-search" />
                                                            </button>
                                                        </span>




                                                    </div>

                                                    {
                                                        classId.length > 0 ?
                                                            (
                                                                <>
                                                                    {
                                                                        !loading ? (
                                                                            <button type="button" className="btn btn-success" style={{ marginTop: 10 }} onClick={() => { updateFeeToClass() }} >
                                                                                Save Changes
                                                                            </button>)
                                                                            :
                                                                            (<div className='loader'>
                                                                                <Circles
                                                                                    height="40"
                                                                                    width="40"
                                                                                    color="#4fa94d"
                                                                                    ariaLabel="circles-loading"
                                                                                    wrapperStyle={{}}
                                                                                    wrapperClass=""
                                                                                    visible={loading}
                                                                                />
                                                                            </div>)
                                                                    }
                                                                </>
                                                            ) : ''
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {
                                        classId ? (<div className="table-responsive">




                                            {
                                                !loading1 ? (
                                                    <table className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Amount</th>



                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                fees.length ?
                                                                    fees.map((value, key) => {

                                                                        return (<tr key={key}>
                                                                            <td width="25%">

                                                                                <input
                                                                                    id={`checkbox-${key}`}
                                                                                    value={value.id}
                                                                                    type="checkbox"
                                                                                    className="messageCheckBox"
                                                                                    checked={value.checked}
                                                                                    onChange={() => updateCheckStatus(key)}
                                                                                />
                                                                                {value.name}
                                                                            </td>
                                                                            <td>
                                                                                <input type='number' value={value.amount} className='form-control' style={{ width: 200 }} onChange={(event) => updateFeeAmount(key, event.target.value)} />
                                                                            </td>

                                                                        </tr>);
                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan='9'>No record found!</td>
                                                                    </tr>
                                                            }




                                                        </tbody>
                                                    </table>)
                                                    :
                                                    (<div className='loader' style={{ marginLeft: '20%' }}>
                                                        <Circles
                                                            height="60"
                                                            width="60"
                                                            color="#4fa94d"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={loading1}
                                                        />
                                                    </div>)
                                            }




                                        </div>) : (
                                            <div style={{ color: 'red' }}>Please select a class</div>
                                        )
                                    }






                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default AssignFeesToClass