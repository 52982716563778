import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';
import { useLocation, useNavigate } from "react-router-dom";

const UpdateFines = () => {

    const location = useLocation();
    const id = location.state.id;

    const navigate = useNavigate();

    const [fineType, setFineType] = useState(location.state.fineType);
    const [fineAfterDays, setFineAfterDays] = useState(location.state.fineAfterDays);
    const [fineAmount, setFineAmount] = useState(location.state.fineAmount);

    const [fines, setFines] = useState([]);
    const [randDep, setRandDep] = useState(0);
    const [errors, setErrors] = useState([]);

    const [months, setMonths] = useState([]);
    const [loading, setLoading] = useState(false);


    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const userData = {
            fineType: fineType,
            fineAfterDays: fineAfterDays,
            fineAmount: fineAmount,
            id: id,
            school_id: location.state.school_id,
            session: location.state.session
        }
        Axios.post(api_url + 'updateFines', qs.stringify(userData))
            .then(resp => {
                setLoading(false);
                if (resp.data.status == 'OK') {
                    toast.success('Update successfully', {
                        position: "top-center",
                    });
                    setFineType("");
                    setFineAfterDays("");
                    setFineAmount("");
                    setErrors([]);

                    navigate('/AddFines');
                }
                else
                    if (resp.data.status == 'DUPLICATE')
                     {
                        toast.error('This record is dupicate', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                else
                    if (resp.data.status == 'V_ERROR') {
                        toast.error('Cannot update try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                    else {
                        toast.error('Cannot update try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data.message);
                    }
                setRandDep(Math.random());
            });
    };
    return (
        <div>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">

                <section className="content-header">
                    <div className="header-icon">
                        <i className="pe-7s-note2" />
                    </div>
                    <div className="header-title">
                        <h1>Fines</h1>
                        <small>Update Fines</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        {/* Form controls */}
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">

                                <ToastContainer />

                                <div className="panel-body">
                                    <form className="col-sm-12" onSubmit={onSubmit}>


                                    <div className="col-sm-7 form-group" style={{ marginTop: -10 }}>
                                            <label>Fine Type</label>
                                            <select className="form-control" id="exampleSelect1" value={fineType} required name="fee_type" onChange={(event) => { setFineType(event.target.value) }}>
                                                <option value="">Select</option>
                                                <option value="Late Fees">Late Fees</option>
                                                <option value="Short Attendance">Short Attendance</option>
                                            </select>
                                            <p className='errorMessage'>{errors.fineType}</p>
                                     </div>

                                     <div className="col-sm-7 form-group" style={{ marginTop: -10 }}>
                                            <label>Fine After Days</label>
                                            <input type="number" className="form-control" required value={fineAfterDays} placeholder="Fine charged after days" min="0" onChange={(e) => setFineAfterDays(e.target.value)} />
                                            <p className='errorMessage'>{errors.fineAfterDays}</p>
                                        </div>


                                        <div className="col-sm-7 form-group" style={{ marginTop: -10 }}>
                                            <label>Fine Amount(Per Day)</label>
                                            <input type="number" className="form-control" required value={fineAmount} placeholder="Fine amount per day" min="0" onChange={(e) => setFineAmount(e.target.value)} />
                                            <p className='errorMessage'>{errors.fineAmount}</p>
                                        </div>

                                        <div className="col-sm-12">
                                            {
                                                !loading ? (
                                                    <button type='submit' className="btn btn-success">Update Fine</button>)
                                                    :
                                                    (<div className='loader'>
                                                        <Circles
                                                            height="60"
                                                            width="60"
                                                            color="#4fa94d"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={loading}
                                                        />
                                                    </div>)
                                            }
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> {/* /.content */}




            </div> {/* /.content-wrapper */}

        </div>
    )
}

export default UpdateFines