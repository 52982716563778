import React, { useState, useEffect } from 'react'
import './PrintTC.css'
import { useLocation, useParams } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';


const PrintTC = () => {

    const location = useLocation();
    const { scode, school_id } = useParams();

    const [studentDetails, setStudentDetails] = useState([]);
    const [tcDetails, setTCDetails] = useState([]);
    const [schoolDetails, setSchoolDetails] = useState([]);
    const [randDep, setRandDep] = useState(0);


    useEffect(() => {
        const userData = {
            scode: scode,
            school_id: school_id,
        }
        Axios.post(api_url + 'getPrintTCDetails', qs.stringify(userData))
            .then((response) => {
                setStudentDetails(response.data.studentDetails);
                setTCDetails(response.data.TCDetails);
                setSchoolDetails(response.data.schoolDetails);
            })
    }, [randDep]);


    return (
        <div style={{ backgroundColor: '#fff', minHeight: '100vh' }}>


            <>
                <div className="main">

                    <div className="header">
                        <h1 style={{ fontSize: 36 }}>{schoolDetails[0]?.name}</h1>
                        <h5 style={{ marginTop: "-7px" }}>
                            Affiliated to {schoolDetails[0]?.affiliated_to}, {schoolDetails[0]?.address}
                        </h5>
                    </div>

                    {/* <br />
                    <div
                        className="top"
                        style={{ textAlign: "center", marginTop: 70, marginBottom: "-200px" }}
                    >
                        <div className="top1" style={{}}>
                            <label
                                style={{
                                    fontWeight: "bold",
                                    backgroundColor: "#ccc",
                                    fontSize: 20,
                                    padding: "10px 10px 10px 10px",
                                    borderRadius: 5
                                }}
                            >
                                Transfer Certificate
                            </label>
                        </div>
                        <div className="clear" />
                    </div>
                    <div style={{ height: 200 }} /> */}

                    <div className="container" style={{marginTop:'40px'}}>
                        <label>School No</label>
                        <label className="lb1"> {schoolDetails[0]?.school_no}</label>
                        <label>Book No</label>
                        <label className="lb1">  </label>
                        <label>TC No</label>
                        <label className="lb1"> </label>
                        <label>Admission No</label>
                        <label className="lb1">{studentDetails[0]?.reg_no}</label>
                        <br />
                        <label>Affiliation No</label>
                        <label className="lb1">  {schoolDetails[0]?.affiliation_no}</label>
                        <label>Renewed Upto</label>
                        <label className="lb1">
                            {Moment(schoolDetails[0]?.reg_valid_upto).format("DD-MM-YYYY")}
                        </label>
                        <label>Status Of School</label>
                        <label className="lb1"> {schoolDetails[0]?.school_type}</label>
                        <br />
                        <label>
                            <b>Registration No Of The Candidate(In Case Class XI to XII)</b>
                        </label>
                        <label className="lb1">
                            {studentDetails[0]?.rollNo}
                        </label>
                        <br />
                        <br />
                        <div className="div1">
                            1.<label id="lb2">Name Of Pupil</label>
                            <label className="lb1">{studentDetails[0]?.name}{studentDetails[0]?.lastName}</label>
                        </div>
                        <div className="div1">
                            2.<label id="lb2">Mother Name</label>
                            <label className="lb1">{studentDetails[0]?.motherName} </label>
                        </div>
                        <div className="div1">
                            3.<label id="lb2">Father Name</label>
                            <label className="lb1">{studentDetails[0]?.father_name}</label>
                        </div>
                        <div className="div1">
                            4.<label id="lb2">Nationality</label>
                            <label className="lb1">Indian</label>
                        </div>
                        <div className="div1">
                            5.
                            <label id="lb2">Whether Candidate Belongs To SC/ST/OBC Category</label>
                            <label className="lb1">
                                {studentDetails[0]?.category}
                            </label>
                        </div>
                        <div className="div1">
                            6.<label id="lb2">Date Of Birth According to admission register</label>{" "}
                            <label className="lb1">
                                {Moment(studentDetails[0]?.dob).format("DD-MM-YYYY")}
                            </label>
                            <br />
                            &nbsp;&nbsp;&nbsp;<label id="lb2">In Words</label>
                            <label className="lb1">
                                {studentDetails?.dob_in_words}
                            </label>
                        </div>
                        <div className="div1">
                            7.<label id="lb2">Whether the student failed</label>
                            <label className="lb1">

                            </label>
                        </div>
                        <div className="div1">
                            8.
                            <label id="lb2">Date of first admission in the school with class</label>
                            <label className="lb1">
                                {studentDetails?.first_admission_date}     {studentDetails?.first_class_name}      </label>
                        </div>
                        <div className="div1">
                            9.<label id="lb2">Subject Offered</label>
                            <label className="lb1">

                            </label>
                        </div>
                        <div className="div1">
                            10.
                            <label id="lb2">Class in which the pupil last studied(In Words)</label>
                            <label className="lb1"> {studentDetails[0]?.class_name}</label>
                        </div>
                        <div className="div1">
                            11.<label id="lb2">School/Board last examination taken result</label>
                            <label className="lb1">

                            </label>
                        </div>
                        <div className="div1">
                            12.
                            <label id="lb2">
                                Whether qualified for promotion in next higher class
                            </label>
                            <label className="lb1">

                            </label>
                        </div>
                        <div className="div1">
                            13.
                            <label id="lb2">
                                Whether the pupil has paid all dues to the school
                            </label>
                            <label className="lb1">
                                {studentDetails?.fees_status}
                            </label>
                        </div>
                        <div className="div1">
                            14.<label id="lb2">Whether the pupil has fee consession</label>
                            <label className="lb1">
                                {studentDetails?.concession_status}
                            </label>
                        </div>
                        <div className="div1">
                            15.
                            <label id="lb2">
                                Whether the pupil is NCC cadet/Boy Scout/Girl Guide
                            </label>
                            <label className="lb1">{tcDetails[0]?.nccCadet}</label>
                        </div>
                        <div className="div1">
                            16.
                            <label id="lb2">
                                Date on Which pupil name stuck off the roll of school
                            </label>
                            <label className="lb1">
                                {Moment(tcDetails[0]?.issueDate).format("DD-MM-YYYY")}
                            </label>
                        </div>
                        <div className="div1">
                            17.<label id="lb2">No. of working days in the academic session</label>
                            <label className="lb1">

                            </label>
                        </div>
                        <div className="div1">
                            18.
                            <label id="lb2">No. of school days pupil present in the school</label>
                            <label className="lb1">
                                {studentDetails?.present_days}
                            </label>
                        </div>
                        <div className="div1">
                            19.
                            <label id="lb2">
                                Games played or extra curricular activities in which pupil usually
                                took part
                            </label>
                            <label className="lb1">{tcDetails[0]?.gamePlayed}</label>
                        </div>
                        <div className="div1">
                            20.<label id="lb2">general conduct</label>
                            <label className="lb1">{tcDetails[0]?.generalConduct}</label>
                        </div>
                        <div className="div1">
                            21.<label id="lb2">reason for living the school</label>
                            <label className="lb1">{tcDetails[0]?.reasonForLeaving}</label>
                        </div>
                        <div className="div1">
                            22.<label id="lb2">Any other remarks</label>
                            <label className="lb1">{tcDetails[0]?.otherRemarks}</label>
                        </div>
                        <div className="div1">
                            23.<label id="lb2">Date of issue of certificate</label>
                            <label className="lb1">
                                {Moment(tcDetails[0]?.issueDate).format("DD-MM-YYYY")}
                            </label>
                        </div>
                        <div className="div1">
                            24.<label id="lb2">Date of application for certificate</label>
                            <label className="lb1">
                                {Moment(tcDetails[0]?.applicationDate).format("DD-MM-YYYY")}
                            </label>
                        </div>

                    </div>
                </div>
                <br />
                <br />
                <div style={{ marginTop: 30 }}>
                    <label style={{ float: "left" }}>
                        <b>
                            Prepared By <br />
                            (Name &amp; Signature)
                        </b>
                    </label>
                    <label style={{ float: "left", marginLeft: 100 }}>
                        <b>
                            Checked By <br />
                            (Name &amp; Designation)
                        </b>
                    </label>
                    <label style={{ float: "right" }}>
                        <b>Signature Principal With official seal</b>
                    </label>
                </div>
            </>



        </div>
    )
}

export default PrintTC