import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';


function UpdateFees() {
  const location = useLocation();
  const id = location.state.id;


  const navigate = useNavigate();


  let fee_month = (location.state.feesMonths).split(",");

  const [name, setName] = useState(location.state.name);
  const [feeType, setFeeType] = useState(location.state.feeType);
  const [feesMonths, setFeesMonths] = useState(fee_month);

  const [months, setMonths] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const [randDep, setRandDep] = useState(0);
  useEffect(() => {

    Axios.get(api_url + 'getMonths/')
      .then((response) => {
        setMonths(response.data.data);
      });

  }, [randDep]);



  const checkInFeesMonthsArray = (monthName, key) => {
    let result = feesMonths.includes(monthName);
    if (result) {
      months[key].checked = 'true';
    }
    return result;
  }


  const selectAll = () => {
    months.map(item => {
      const ind = feesMonths.indexOf(item.name);
      if (ind > -1) {
        //feesMonths.splice(ind,1); 
      }
      else {
        feesMonths.push(item.name);
      }
    });

    setMonths(months.map(topping => ({ ...topping, checked: true })))
  }

  const unSelectAll = () => {

    months.map(item => {
      const ind = feesMonths.indexOf(item.name);
      if (ind > -1) {
        feesMonths.splice(ind, 1);
      }
      else {
        //feesMonths.push(item.name); 
      }
    });

    setMonths(months.map(topping => ({ ...topping, checked: false })))
  };

  const updateCheckStatus = (index, name) => {

    const ind = feesMonths.indexOf(name);
    if (ind > -1) {
      feesMonths.splice(ind, 1);
    }
    else {
      feesMonths.push(name);
    }


    setMonths(
      months.map((topping, currentIndex) =>
        currentIndex === index
          ? { ...topping, checked: !topping.checked }
          : topping
      )
    )
  }
  // console.log(months);
  // console.log(feesMonths);



  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const userData = {
      name: name,
      fee_type: feeType,
      months: months,
      id: id,
      school_id: location.state.school_id,
      session: location.state.session
    }
    Axios.post(api_url + 'updateFees', qs.stringify(userData))
      .then(resp => {
        setLoading(false);
        if (resp.data.status == 'OK') {
          setName("");
          setFeeType("");
          setMonths([]);
          setErrors([]);

          toast.success('Updated successfully', {
            position: "top-center",
          });
          navigate('/AddFees');
        }
        else
          if (resp.data.status == 'V_ERROR') {
            setErrors(resp.data);
          }
          else {
            setErrors(resp.data.message);
          }
      });
  };


  return (
    <div>
      <Header></Header>
      <SideMenu></SideMenu>
      <div className="content-wrapper" style={{ minHeight: '100vh' }}>

        <section className="content-header">
          <div className="header-icon">
            <i className="pe-7s-note2" />
          </div>
          <div className="header-title">
            <h1>Fees</h1>
            <small>Edit Fees</small>
            <ol className="breadcrumb hidden-xs">
              <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
              <li className="active">Dashboard</li>
            </ol>
          </div>
        </section>
        {/* Main content */}
        <section className="content">
          <div className="row">
            {/* Form controls */}
            <div className="col-sm-12">
              <div className="panel panel-bd lobidrag">


                <div className="panel-body">
                  <form className="col-sm-12" onSubmit={onSubmit}>

                    <div className="col-sm-7 form-group">
                      <label>Fee Name</label>
                      <input type="text" className="form-control" placeholder="Enter Fee Name" name="name" required value={name} onChange={(e) => setName(e.target.value)} autoFocus />
                      <p className='errorMessage'>{errors.name}</p>
                    </div>

                    <div className="col-sm-7 form-group" style={{ marginTop: -10 }}>
                      <label>Fee Type</label>

                      <select className="form-control" id="exampleSelect1" value={feeType} required name="fee_type" onChange={(event) => { setFeeType(event.target.value) }}>
                        <option value="">Select</option>
                        <option value="ONETIME">One Time</option>
                        <option value="MONTHLY">Monthly</option>
                      </select>
                      <p className='errorMessage'>{errors.feeType}</p>


                      {
                        feeType == 'MONTHLY' ? (<div style={{ display: 'flex', flexWrap: 'wrap' }}>

                          <button type="button" className="btn btn-labeled btn-success m-b-5" onClick={selectAll}>
                            <span className="btn-label">
                              <i className="glyphicon glyphicon-ok" />
                            </span>
                            Select All
                          </button>

                          <button type="button" className="btn btn-labeled btn-success m-b-5" onClick={unSelectAll}>
                            <span className="btn-label">
                              <i className="glyphicon glyphicon-ok" />
                            </span>
                            Deselect All
                          </button>

                          {
                            months.length ?
                              (months).map((value, key) => {

                                return (
                                  <div className="inbox-avatar" key={key} style={{ borderBottom: '1px solid #eee', display: 'flex' }}>

                                    <input
                                      id={`checkbox-${key}`}
                                      value={value.id}
                                      type="checkbox"
                                      className="messageCheckBox"
                                      checked={checkInFeesMonthsArray(value.name, key)}
                                      onChange={() => updateCheckStatus(key, value.name)}
                                    />

                                    <div style={{ marginLeft: -8, marginRight: 5, marginTop: 1 }}>
                                      <div className="avatar-name" style={{ marginBottom: 0 }}>{value.name}</div>
                                    </div>

                                  </div>);
                              })
                              :
                              <div></div>
                          }

                        </div>) : null

                      }








                    </div>

                    <div className="col-sm-12">
                      {
                        !loading ? (
                          <button type='submit' className="btn btn-success">Update Fees</button>)
                          :
                          (<div className='loader'>
                            <Circles
                              height="60"
                              width="60"
                              color="#4fa94d"
                              ariaLabel="circles-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={loading}
                            />
                          </div>)
                      }
                    </div>


                  </form>
                </div>
              </div>
            </div>
          </div>
        </section> {/* /.content */}












      </div> {/* /.content-wrapper */}
    </div>
  )
}

export default UpdateFees