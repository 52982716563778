import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';

const AddFees = () => {
    const { schoolID, schoolSession, schoolUserType } = useContext(SchoolContext);

    const [name, setName] = useState();
    const [feeType, setFeeType] = useState();
    const [school_id, setSchoolId] = useState(9999);
    const [randDep, setRandDep] = useState(0);
    const [errors, setErrors] = useState([]);

    const [months, setMonths] = useState([]);
    const [fees, setFees] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }
        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getFees', qs.stringify(userData))
            .then((response) => {
                setFees(response.data.data);
            });

        Axios.get(api_url + 'getMonths/')
            .then((response) => {
                setMonths(response.data.data);
            });
    }, [randDep]);


    const selectAll = () => {
        setMonths(months.map(topping => ({ ...topping, checked: true })))
    }

    const unSelectAll = () => {
        setMonths(months.map(topping => ({ ...topping, checked: false })))
    };

    const updateCheckStatus = index => {
        setMonths(
            months.map((topping, currentIndex) =>
                currentIndex === index
                    ? { ...topping, checked: !topping.checked }
                    : topping
            )
        )
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const userData = {
            name: name,
            fee_type: feeType,
            months: months,
            school_id:schoolID,
            session:schoolSession,
        }

        //console.log(userData);

        Axios.post(api_url + 'addFees', qs.stringify(userData))
            .then(resp => {
                setLoading(false);
                if (resp.data.status == 'OK') {
                    toast.success('Created successfully', {
                        position: "top-center",
                    });
                    setName("");
                    setFeeType("");
                    setMonths([]);
                    setErrors([]);
                }
                else
                    if (resp.data.status == 'DUPLICATE')
                     {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                else
                    if (resp.data.status == 'V_ERROR') {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                    else {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data.message);
                    }
                setRandDep(Math.random());



            });




    };

    const deleteFees = (id) => {
        const delData = {
            id: id
        }
        Axios.put(api_url + 'deleteFees/', qs.stringify(delData))
            .then((resp) => {
                if (resp.data.status == 'OK') {
                    toast.success('Deleted Successfully', {
                        position: "top-center",
                    });

                    setRandDep(Math.random());
                }
            })
    }

    return (
        <div>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">

                <section className="content-header">
                    <div className="header-icon">
                        <i className="pe-7s-note2" />
                    </div>
                    <div className="header-title">
                        <h1>Fees</h1>
                        <small>Add Fee</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        {/* Form controls */}
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">

                                <ToastContainer />

                                <div className="panel-body">
                                    <form className="col-sm-12" onSubmit={onSubmit}>

                                        <div className="col-sm-7 form-group">
                                            <label>Fee Name</label>
                                            <input type="text" className="form-control" placeholder="Enter Fee Name" name="name" required value={name} onChange={(e) => setName(e.target.value)} autoFocus />
                                            <p className='errorMessage'>{errors.name}</p>
                                        </div>

                                        <div className="col-sm-7 form-group" style={{ marginTop: -10 }}>
                                            <label>Fee Type</label>

                                            <select className="form-control" id="exampleSelect1" value={feeType} required name="fee_type" onChange={(event) => { setFeeType(event.target.value) }}>
                                                <option value="">Select</option>
                                                <option value="ONETIME">One Time</option>
                                                <option value="MONTHLY">Monthly</option>
                                            </select>
                                            <p className='errorMessage'>{errors.feeType}</p>


                                            {
                                                feeType == 'MONTHLY' ? (<div style={{ display: 'flex', flexWrap: 'wrap' }}>

                                                    <button type="button" className="btn btn-labeled btn-success m-b-5" onClick={selectAll}>
                                                        <span className="btn-label">
                                                            <i className="glyphicon glyphicon-ok" />
                                                        </span>
                                                        Select All
                                                    </button>

                                                    <button type="button" className="btn btn-labeled btn-success m-b-5" onClick={unSelectAll}>
                                                        <span className="btn-label">
                                                            <i className="glyphicon glyphicon-ok" />
                                                        </span>
                                                        Deselect All
                                                    </button>

                                                    {
                                                        months.length ?
                                                            months.map((value, key) => {

                                                                return (
                                                                    <div className="inbox-avatar" key={key} style={{ borderBottom: '1px solid #eee', display: 'flex' }}>

                                                                        <input
                                                                            id={`checkbox-${key}`}
                                                                            value={value.id}
                                                                            type="checkbox"
                                                                            className="messageCheckBox"
                                                                            checked={value.checked}
                                                                            onChange={() => updateCheckStatus(key)}
                                                                        />

                                                                        <div style={{ marginLeft: -8, marginRight: 5, marginTop: 1 }}>
                                                                            <div className="avatar-name" style={{ marginBottom: 0 }}>{value.name}</div>
                                                                        </div>

                                                                    </div>);
                                                            })
                                                            :
                                                            <div></div>
                                                    }

                                                </div>) : null

                                            }








                                        </div>

                                        <div className="col-sm-12">
                                            {
                                                !loading ? (
                                                    <button type='submit' className="btn btn-success">Save Fees</button>)
                                                    :
                                                    (<div className='loader'>
                                                        <Circles
                                                            height="60"
                                                            width="60"
                                                            color="#4fa94d"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={loading}
                                                        />
                                                    </div>)
                                            }
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> {/* /.content */}











                <section className="content" >
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-heading">
                                    <div className="btn-group">
                                        <h3>Existing Records</h3>
                                    </div>
                                </div>
                                <div className="panel-body">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Serial No</th>
                                                    <th>Name</th>
                                                    <th>Fees Type</th>

                                                    <th>Fees Months</th>
                                                    <th>Created At</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    fees.length ?
                                                        fees.map((value, key) => {
                                                            let myString = value.months
                                                            let resultArr = myString.split(",")

                                                            return (<tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{value.name}</td>

                                                                <td >{value.fee_type}</td>
                                                                <td>
                                                                    {

                                                                        resultArr.join(", ")
                                                                    }
                                                                </td>
                                                                <td>{Moment(value.created_at).format("DD-MM-YYYY, h:m:s")}</td>
                                                                <td style={{width:'15%',minWidth:'100'}}>

                                                                    {/* <Link
                                                to={{pathname: `/classes/edit/${value.id}`}}
                                                state={{
                                                    id: value.id,
                                                    name:value.name
                                                    }}>
                                                 <button type="button" className="btn btn-info btn-xs" data-toggle="modal" data-target="#ordine"><i className="fa fa-pencil" />
                                            </button>
                                            </Link> */}


                                                                    <Link
                                                                        to={{ pathname: `/EditFees` }}
                                                                        state={{                                                        
                                                                            id: value.id,
                                                                            name: value.name,
                                                                            feeType: value.fee_type,
                                                                            feesMonths: value.months,
                                                                            months: months,
                                                                            school_id: value.school_id,
                                                                            session: value.session
                                                                        }}>
                                                                        <button type="button" className="btn btn1 btn-info btn-xs" data-toggle="modal" data-target="#ordine" style={{ marginBottom: 5 }}><i className="fa fa-pencil" />
                                                                        </button>
                                                                    </Link>


                                                                    <button type="button" className="btn btn1 btn-danger btn-xs" onClick={() => window.confirm('Are you sure you wish to delete this item?') ? deleteFees(value.id) : ""}><i className="fa fa-trash-o" />
                                                                    </button>
                                                                </td>
                                                            </tr>);
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan='5'>To record found!</td>
                                                        </tr>
                                                }


                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div> {/* /.content-wrapper */}

        </div>
    )
}

export default AddFees