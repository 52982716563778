import React, { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SchoolContext } from '../App';
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { ToastContainer, toast } from 'react-toastify';


const Header = () => {
  const navigate = useNavigate();
  const { schoolName, setSchoolName, schoolID, setSchoolID, schoolSession, setSchoolSession, schoolUserType, setSchoolUserType } = useContext(SchoolContext);

  const  setContextVariables = async (localLoginToken, schoolUserType) => {
    const userData = {
      scode: localLoginToken,
      userType: schoolUserType,
    }

    await Axios.post(api_url + 'validateUserInformationFromToken', qs.stringify(userData))
      .then(resp => {
        if (resp.data.status == 'OK') {
          setSchoolName(resp.data.name);
          setSchoolID(resp.data.id);
          setSchoolSession(localStorage.getItem('schoolSession'));
          setSchoolUserType(schoolUserType);
        }
        else
          if (resp.data.status == 'NOT_FOUND' || resp.data.status == 'V_ERROR') {
            toast.error(resp.data.message, {
              position: "top-center",
            });
            navigate('/');
          }
      });
  }
  useEffect(() => {
    const setLocalItems = () => {
      let localLoginToken = localStorage.getItem('loginToken');
      let schoolUserType = localStorage.getItem('schoolUserType');
      if (localLoginToken != '' && localLoginToken != null && schoolUserType == 'SCHOOL' && schoolUserType != null) 
      {
        if (schoolID == '' || schoolID == null) 
        {
          setContextVariables(localLoginToken, schoolUserType);
        }
      }
      else
      {
        navigate('/');
      }
    }
    setLocalItems();
  }, []);


  const handleLogout = () =>
  {
          setSchoolName("");
          setSchoolID("");
          setSchoolSession("");
          setSchoolUserType("");
          localStorage.removeItem('loginToken');
          localStorage.removeItem('schoolUserType');
          localStorage.removeItem('schoolSession');
        
          toast.success("Logout successfylly", {
            position: "top-center",
          });
          navigate('/');
         
  }

  return (
    <div>
      <header className="main-header">
        <a href="index.html" className="logo"> {/* Logo */}
          <span className="logo-mini">
            {/*<b>A</b>BD*/}
            <img src="assets/dist/img/mini-logo.png" />
          </span>
          <span className="logo-lg">
            {/*<b>Admin</b>BD*/}
            {schoolName}
          </span>
        </a>
        {/* Header Navbar */}
        <nav className="navbar navbar-static-top ">
          <a href="#" className="sidebar-toggle" data-toggle="offcanvas" role="button"> {/* Sidebar toggle button*/}
            <span className="sr-only">Toggle navigation</span>
            <span className="fa fa-tasks" />
          </a>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
            
              {/* user */}
              <li className="dropdown dropdown-user admin-user">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <div className="user-image">
                    <img src="assets/dist/img/avatar4.png" className="img-circle" height={40} width={40} alt="User Image" />
                  </div>
                </a>
                <ul className="dropdown-menu">
                  <li><a href="#"><i className="fa fa-users" /> User Profile</a></li>
                  <li><a href="#"><i className="fa fa-gear" /> Settings</a></li>
                  <li><a  href="#" onClick={handleLogout}><i className="fa fa-sign-out" /> Logout</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </div>
  )
}

export default Header