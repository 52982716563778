import React from 'react';
import { Link } from "react-router-dom";

const SideMenu = () => {
  return (
<div>
        <aside className="main-sidebar" >
            {/* sidebar */}
            <div className="sidebar" >
              {/* Sidebar user panel */}
              <div className="user-panel" >
                <div className="image pull-left" >
                  <img src="assets/dist/img/avatar5.png" className="img-circle" alt="User Image" />
                </div>
                <div className="info">
                  <h4>Welcome</h4>
                  <p>Mr. Admin</p>
                </div>
              </div>
              {/* sidebar menu */}
              <ul className="sidebar-menu" >
                <li className="active">
                 <Link to="/WelcomeSchool"><i className="fa fa-hospital-o" /><span>Dashboard</span></Link>
                </li>


                <li className="treeview">                 
                  <a href="#">
                    <i className="fa fa-bars" /><span>Setup</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right" />
                    </span>
                  </a>
                  <ul className="treeview-menu">                     
                      <li>
                      <Link to="/SchoolDetails">School Details</Link>
                      </li>                
                    <li>
                      <Link to="/AddClass">Classes</Link>
                    </li>   
                    <li>
                      <Link to="/AddSubject">Subjects</Link>
                    </li>   
                    <li>
                      <Link to="/AddHouse">House</Link>
                    </li>                                     
                  </ul>
                </li>


                <li className="treeview">                 
                  <a href="#">
                    <i className="fa fa-users" /><span>Students</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right" />
                    </span>
                  </a>
                  <ul className="treeview-menu">                    
                    <li>
                      <Link to="/AddStudent">Add Student</Link>
                    </li>
                    <li>
                      <Link to="/ImportStudent">Import From Excel</Link>
                    </li>
                    <li>
                      <Link to="/ViewStudents">View Students</Link>
                    </li>                    
                  </ul>
                </li>



                <li className="treeview">                 
                  <a href="#">
                    <i className="fa fa-comments" /><span>Messages</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right" />
                    </span>
                  </a>
                  <ul className="treeview-menu">                    
                    <li>
                      <Link to="/SendMessage">Send Message</Link>
                    </li>
                    <li>
                      <Link to="/ShowMessages">Message Delivery Report</Link>
                    </li>                    
                  </ul>
                </li>


                <li className="treeview">                 
                  <a href="#">
                    <i className="fa fa-check" /><span>Attendance</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right" />
                    </span>
                  </a>
                  <ul className="treeview-menu">                    
                    <li>
                      <Link to="/AddAttendance">Add Attendance</Link>
                    </li>
                    <li>
                      <Link to="/ShowAttendance">Attendance Report</Link>
                    </li>                    
                  </ul>
                </li>
           

                <li className="treeview">                 
                  <a href="#">
                    <i className="fa fa-money" /><span>Fees</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right" />
                    </span>
                  </a>     
                  <ul className="treeview-menu">                    
                    <li>
                      <Link to="/AddFees">Create Fees</Link>
                    </li>
                    <li>
                      <Link to="/AssignFeesToClass">Assign Fees To Class</Link>
                    </li>

                    <li>
                      <Link to="/AddFines">Set Fines</Link>
                    </li>


                    <li>
                      <Link to="/FeesPayment">Fees Payment</Link>
                    </li>

                    <li>
                      <Link to="/ViewReceipts">Fees Receipts</Link>
                    </li>
                                     
                  </ul>             
                </li>


                <li className="treeview">                 
                  <a href="#">
                    <i className="fa fa-file-o" /><span>Certificates</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right" />
                    </span>
                  </a>     
                  <ul className="treeview-menu">                    
                    <li>
                      <Link to="/GenerateTC">Generate TC</Link>
                      <Link to="/TCRecords">TC Records</Link>
                    </li>
                   
                  </ul>             
                </li>


             
           


           
             
              
              </ul>
            </div> {/* /.sidebar */}
          </aside>
    </div>
  )
}

export default SideMenu