import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../../App';

const FeesReceipts = () => {

    const { studentID } = useContext(SchoolContext);

    const [receipts, setReceipts] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
       handleRegNoChange();
    }, [studentID]);



    const handleRegNoChange = async () => {
        setLoading(true);
        try {
           
            const userData = {
                studentID: studentID,
            }

            await Axios.post(api_url + 'student/feesReceipts', qs.stringify(userData))
                .then(resp => {                  
                    setLoading(false);
                    if (resp.data['message'] == 'OK') 
                    {
                        setReceipts(resp.data.data);                           
                    }

                    setLoading(false);     

                });

        }
        catch (err) {
            console.log(err);
        }

    }
    

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Fee Receipts</h1>
                        <small>Fee Receipts list</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                               

                                <div className="panel-body">

                                   

                                    <div className="table-responsive">
                                        {
                                            !loading ? (
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Receipt No</th>
                                                            <th>Name</th>
                                                            <th>Reg No</th>

                                                            <th>Contact No</th>
                                                            <th>Class</th>
                                                            <th>Amount</th>

                                                            <th>Concession</th>
                                                            <th>Created At</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            receipts.length ?
                                                                receipts.map((value, key) => {

                                                                    return (<tr key={key}>
                                                                        <td>{value.receipt_no}</td>
                                                                        <td>{value.student_name}</td>
                                                                        <td>{value.reg_no}</td>
                                                                        <td>{value.contact_no}</td>

                                                                        <td>{value.class_name}</td>
                                                                        <td>{value.amount}</td>
                                                                        <td>{value.concession}</td>

                                                                        <td>{Moment(value.receipt_date).format("DD-MM-YYYY, h:m:s")}</td>
                                                                        <td>
                                                                            <Link                                                                              
                                                                                to={{                                                                                
                                                                                    pathname: `/PrintReceipt/${value.scode}/${value.school_id}`
                                                                                }}
                                                                                target='_blank'
                                                                            >
                                                                                <button type="button" title='Print' className="btn btn1 btn-info btn-xs" data-toggle="modal" data-target="#ordine"><i className="fa fa-print" />
                                                                                </button>
                                                                            </Link>



                                                                        </td>
                                                                    </tr>);
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan='9'>No record found!</td>
                                                                </tr>
                                                        }

                                                        <tr>
                                                            <td colSpan="9" className='table_bottom_total'>Total {receipts.length} records found</td>
                                                        </tr>


                                                    </tbody>
                                                </table>)
                                                :
                                                (<div className='loader' style={{ marginLeft: '20%' }}>
                                                    <Circles
                                                        height="60"
                                                        width="60"
                                                        color="#4fa94d"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={loading}
                                                    />
                                                </div>)
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default FeesReceipts