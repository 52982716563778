import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';

const SendMessage = () => {

    const { schoolID, schoolSession, schoolUserType } = useContext(SchoolContext);

    const [classes, setClasses] = useState([]);
    const [classId, setClassId] = useState("");
    const [category, setCategory] = useState("");

    const [randDep, setRandDep] = useState(0);
    const [students, setStudents] = useState([]);
    const [studentsAll, setStudentsAll] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    const [messageText, setMessageText] = useState("");
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(true);

    const [errors, setErrors] = useState([]);
    const [messageLink, setMessageLink] = useState("");
    const [messagePhoto, setMessagePhoto] = useState("");

    const getStudents = async () => {

        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }

        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getStudents', qs.stringify(userData))
            .then((response) => {
                setLoading1(false);
                if (response.data.message == 'OK') {
                    setStudents(response.data.data);
                    setStudentsAll(response.data.data);
                }

            })
    }

    useEffect(() => {
        getStudents();
        getClasses();

    }, [randDep]);

    const handleOnSearchInput = async text => {
        setSearchQuery(text);
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }

        const filteredData = studentsAll.filter(student => {
            if ((student.reg_no.toLowerCase().includes(text.toLowerCase())) || (student.name.toLowerCase().includes(text.toLowerCase())) || (student.contact_no.toLowerCase().includes(text.toLowerCase())) || (student.class_name.toLowerCase().includes(text.toLowerCase()))) {
                return student;
            }
        });


        if (filteredData.length) {
            setStudents([...filteredData]);
        }
        else {
            setStudents([]);
        }
    };

    const updateCheckStatus = index => {
        setStudents(
            students.map((topping, currentIndex) =>
                currentIndex === index
                    ? { ...topping, checked: !topping.checked }
                    : topping
            )
        )

        // or
        // setToppings([
        //   ...toppings.slice(0, index),
        //   { ...toppings[index], checked: !toppings[index].checked },
        //   ...toppings.slice(index + 1),
        // ]);
    }

    const selectAll = () => {
        setStudents(students.map(topping => ({ ...topping, checked: true })))
    }
    const unSelectAll = () => {
        setStudents(students.map(topping => ({ ...topping, checked: false })))
    };


    const fileChangedHandler = event => {
        let file = event.target.files[0];
        let fileSize = ((file.size)/ 1024).toFixed(2);
        if(fileSize > 100)
        {
            alert(`File size is ${fileSize}KB Please select a file less than 100KB`);
            setMessagePhoto("");
            document.getElementById('messagePhoto').value = null;
        }
        else
        {
            setMessagePhoto(file);
        }
      };

    const sendMessageWithoutFile = () => {
        if (messageText == '') {
            toast.warning('Please enter some message', {
                position: "top-center",
            });
        }
        else {
            let partialStudentDetails = students.map((
                { id, name, checked }) => ({ id, name, checked }));

            const userData = {
                student_data: partialStudentDetails,
                message: messageText,
                sender_id: schoolID,
                sender_type: schoolUserType,
                session: schoolSession
            }

            setLoading(true);
            Axios.post(api_url + 'sendMessage', qs.stringify(userData))
                .then(resp => {
                    setLoading(false);
                    if (resp.data.status == 'OK') {
                        setMessageText("");
                        toast.success('Message sent successfully', {
                            position: "top-center",
                        });

                    }
                    else
                        if (resp.data.status == 'NO_RECEIVER') {
                            toast.warning('Please select atleast one student', {
                                position: "top-center",
                            });

                        }
                        else
                            if (resp.data.status == 'V_ERROR') {
                                toast.error('Cannot send message try again', {
                                    position: "top-center",
                                });
                            }
                            else {
                                toast.error('Cannot send message try again', {
                                    position: "top-center",
                                });
                            }

                });
        }

    }

    const sendMessage = async(event) => {
        event.preventDefault();
        if (messageText == '') {
            toast.warning('Please enter some message', {
                position: "top-center",
            });
        }
        else {

            let partialStudentDetails = students.map((
                { id, name, checked }) => ({ id, name, checked }));

            const formData = new FormData();
           
            formData.append('photo',messagePhoto);
            formData.append('url', messageLink);
            formData.append('student_data', JSON.stringify(partialStudentDetails));
            formData.append('message',messageText);
            formData.append('sender_id',schoolID);
            formData.append('sender_type',schoolUserType);
            formData.append('session',schoolSession);
        
            //formData.forEach((item)=>{console.log(item)});

            setLoading(true);
            try{
                const resp = await Axios.post(api_url + 'sendMessage',formData,{
                    headers:{
                      "Content-Type":"multipart/form-data",
                    },
                  });

                  setLoading(false);
                  //console.log(resp);
                  if (resp.data.status == 'OK') {
                      setMessageText("");
                      setMessageLink("");
                      setMessagePhoto();
                      document.getElementById('messagePhoto').value = null;
                      toast.success('Message sent successfully', {
                          position: "top-center",
                      });

                  }
                  else
                      if (resp.data.status == 'NO_RECEIVER') {
                          toast.warning('Please select atleast one student', {
                              position: "top-center",
                          });

                      }
                      else
                          if (resp.data.status == 'V_ERROR') {
                              toast.error('Cannot send message try again', {
                                  position: "top-center",
                              });
                          }
                          else {
                              toast.error('Cannot send message try again', {
                                  position: "top-center",
                              });
                          }

            }
            catch(error){
                setLoading(false);
                console.log("API ERR:",error)
          
              }
           
        }

    }



    const getClasses = () => {
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }

        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getClasses', qs.stringify(userData))
            .then((response) => {
                setClasses(response.data.data)
            })
    }


    const classChangeHandler = (event) => {
        let text = event.target.value;
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }

        const filteredData = studentsAll.filter(student => {
            if ((student.class_id == text)) {
                return student;
            }
        });


        if (filteredData.length) {
            setStudents([...filteredData]);
        }
        else {
            setStudents([]);
        }

        setClassId(text);

    };


    const categoryChangeHandler = (event) => {
        let text = event.target.value;
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }

        const filteredData = studentsAll.filter(student => {
            if ((student.category == text)) {
                return student;
            }
        });


        if (filteredData.length) {
            setStudents([...filteredData]);
        }
        else {
            setStudents([]);
        }

        setCategory(text);

    };

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Messages Control Panel</h1>
                        <small>Send Message</small>

                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">




                                <div className="panel-body">

                                    <div className="row">
                                        <div className="panel-header">


                                            <div className="col-md-12 col-sm-6 col-xs-12">
                                                <div className="dataTables_length">




                                                    <div className="row">


                                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                                            <input type="text" className="form-control" placeholder="search by reg no, name, contact no or class" onChange={(event) => { handleOnSearchInput(event.target.value) }} />
                                                        </div>

                                                        <div className='col-lg-2 col-md-3 col-sm-6'>
                                                            <select className="form-control" value={classId} required name="class" onChange={classChangeHandler} >
                                                                <option value="">Select Class</option>
                                                                {
                                                                    classes.length ?
                                                                        classes.map((value) => {
                                                                            return (<option key={value.id} value={value.id}>{value.name}</option>);
                                                                        })
                                                                        :
                                                                        <option value="">Not Record Found!</option>
                                                                }
                                                            </select>
                                                        </div>



                                                        <div className='col-lg-2 col-md-3 col-sm-6'>
                                                            <select className="form-control" value={category} onChange={categoryChangeHandler} >
                                                                <option value="">Select Category</option>
                                                                <option value="General">General</option>
                                                                <option value="OBC">OBC</option>
                                                                <option value="SC">SC</option>
                                                                <option value="ST">ST</option>
                                                                <option value="Physically Disabled">Physically Disabled</option>
                                                            </select>
                                                        </div>











                                                        {/* <span className="input-group-btn">
<button className="btn btn-primary" type="button" >
    <span className="glyphicon glyphicon-search" />
</button>
</span> */}






                                                    </div>




                                                    <div style={{ marginBottom: -10, marginTop: 10 }}>
                                                        <button type="button" className="btn btn-labeled btn-success m-b-5" onClick={selectAll}>
                                                            <span className="btn-label">
                                                                <i className="glyphicon glyphicon-ok" />
                                                            </span>
                                                            Select All
                                                        </button>


                                                        <button type="button" className="btn btn-labeled btn-danger m-b-5" onClick={unSelectAll}>
                                                            <span className="btn-label">
                                                                <i className="glyphicon glyphicon-remove" />
                                                            </span>
                                                            Unselect All
                                                        </button>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {
                                        !loading1 ? (
                                            <div className="mailbox">

                                                <div className="mailbox-body">
                                                    <div className="row m-0">

                                                        <div className="col-xs-12 col-sm-12 col-md-8 p-0">




                                                            <div className="mailbox-content" style={{ borderRight: '1px solid #eee' }}>



                                                                {
                                                                    students.length ?
                                                                        students.map((value, key) => {

                                                                            return (
                                                                                <div className="inbox-avatar" key={key} style={{ borderBottom: '1px solid #eee' }}>

                                                                                    <input
                                                                                        id={`checkbox-${key}`}
                                                                                        value={value.id}
                                                                                        type="checkbox"
                                                                                        className="messageCheckBox"
                                                                                        checked={value.checked}
                                                                                        onChange={() => updateCheckStatus(key)}
                                                                                    />

                                                                                    {
                                                                                        value.gender == 'MALE' ? (
                                                                                            <img src="assets/dist/img/avatar.png" className="border-green hidden-xs hidden-sm" />)
                                                                                            :
                                                                                            (<img src="assets/dist/img/avatar2.png" className="border-green hidden-xs hidden-sm" />)
                                                                                    }


                                                                                    <div className="inbox-avatar-text">
                                                                                        <div className="avatar-name" style={{ marginBottom: 3 }}>{value.name} ({value.reg_no})</div>

                                                                                        <div>
                                                                                            <span className="bg-green badge avatar-text">
                                                                                                Father Name  : {value.father_name}
                                                                                            </span>

                                                                                            <span className="badge avatar-text" style={{ backgroundColor: '#7A316F' }}>
                                                                                                Class  : {value.class_name}
                                                                                            </span>

                                                                                            <span className="bg-gray badge avatar-text">
                                                                                                Contact No  : {value.contact_no}
                                                                                            </span>
                                                                                        </div>




                                                                                    </div>
                                                                                    {/* <div className="inbox-date hidden-sm hidden-xs hidden-md" style={{marginRight:20}}>
                                                                    <div className="date">{Moment(value.created_at).format("DD-MM-YYYY, h:m:s")}</div>
                                                                    <div>
                                                                        <small>#1</small>
                                                                    </div>
                                                                </div> */}
                                                                                </div>);
                                                                        })
                                                                        :
                                                                        <div style={{ textAlign: 'center', marginTop: 10 }}>No record found!</div>
                                                                }


                                                                <div className='div_bottom_total'>Total {students.length} records found</div>


                                                            </div>






                                                        </div>


                                                        <form  onSubmit={sendMessage}>
                                                        <div className="col-xs-12 col-sm-12 col-md-4 p-0">
                                                            <div className="inbox-mail p-20">

                                                                <div className="col-sm-12 row">
                                                                    <label>Any URL</label>
                                                                    <input type="text" className="form-control" value={messageLink} placeholder="Enter any url" onChange={(event) => { setMessageLink(event.target.value) }} />
                                                                    <p className='errorMessage'>{errors.messageLink}</p>
                                                                </div>

                                                                         <div className="col-sm-12 row">


                                                                           {messagePhoto ? ( <img src={messagePhoto ? (URL.createObjectURL(messagePhoto)) : null} className="border-green hidden-xs hidden-sm" 
                                                                            style={{ width: 80, height: 80, borderRadius: '50%' }} 
                                                                            />) : null }
                                                                           


                                                                    <label>Photo/File(Max-100kb)</label>
                                                                    <input type="file" id='messagePhoto' className="form-control"
                                                                        //onChange={(e) => setMessagePhoto(e.target.files[0])}
                                                                        onChange={fileChangedHandler}       
                                                                        placeholder="file" />
                                                                    <p className='errorMessage'>{errors.messagePhoto}</p>
                                                                </div>


                                                                <div className="form-group row">
                                                                    <div className="col-sm-12">
                                                                        <textarea rows={10} className="form-control" placeholder='Enter message here' onChange={(e) => setMessageText(e.target.value)} value={messageText} />
                                                                    </div>
                                                                </div>


                                                                <div className="btn-group" style={{ display: 'flex', justifyContent: 'center' }}>


                                                                    {
                                                                        !loading ? (
                                                                            <button type="submit" className="btn btn-success" >
                                                                                Send Message
                                                                            </button>)
                                                                            :
                                                                            (<div className='loader'>
                                                                                <Circles
                                                                                    height="60"
                                                                                    width="60"
                                                                                    color="#4fa94d"
                                                                                    ariaLabel="circles-loading"
                                                                                    wrapperStyle={{}}
                                                                                    wrapperClass=""
                                                                                    visible={loading}
                                                                                />
                                                                            </div>)
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                        </form>


                                                    </div>
                                                </div>
                                            </div>)
                                            :
                                            (<div className='loader' style={{ marginLeft: '20px', marginTop: '10px' }}>
                                                <Circles
                                                    height="60"
                                                    width="60"
                                                    color="#4fa94d"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={loading1}
                                                />
                                            </div>)
                                    }
















                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default SendMessage