import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';

const ImportStudent = () => {

    const { schoolID, schoolSession } = useContext(SchoolContext);
    const [classes, setClasses] = useState([]);

    const [classId, setClassId] = useState("");
    const [section, setSection] = useState("");

    const [excelFile, setExcelFile] = useState("");


    const [errors, setErrors] = useState([]);
    const [randDep, setRandDep] = useState(0);

    const [loading, setLoading] = useState(false);


    useEffect(() => {

        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }

        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getClasses', qs.stringify(userData))
            .then((response) => {
                setClasses(response.data.data)
            })
    }, [randDep]);

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();

        formData.append('class_id', classId);
        formData.append('section', section);
        formData.append('file', excelFile);

        formData.append('school_id', schoolID);
        formData.append('session', schoolSession);

        fetch(api_url + "importExcel", {
            method: 'POST',
            body: formData
        }).then((response) => response.json())
            .then(resp => {
                setLoading(false);
                if (resp.status == 'OK') {
                    toast.success('Imported successfully', {
                        position: "top-center",
                    });

                    setErrors([]);
                    setClassId("");
                    setSection("");

                    setExcelFile();
                    document.getElementById('excelFile').value = null;
                }
                else
                    if (resp.status == 'INVALID') {
                        toast.error('Input file should be a .csv file', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                    else
                        if (resp.status == 'V_ERROR') {
                            toast.error('Cannot import try again', {
                                position: "top-center",
                            });
                            setErrors(resp.data);
                        }
                        else {
                            toast.error('Cannot import try again', {
                                position: "top-center",
                            });
                            setErrors(resp.message);
                        }
                setRandDep(Math.random());
            });
    }
    return (
        <div>
            <Header></Header>
            <SideMenu></SideMenu>

            <div className="content-wrapper">

                <section className="content-header">
                    <div className="header-icon">
                        <i className="pe-7s-note2" />
                    </div>
                    <div className="header-title">
                        <h1>Students</h1>
                        <small>Import Student</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="row">
                        {/* Form controls */}
                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-heading">
                                    <div className="btn-group">
                                        <Link className="btn btn-primary" to="/ViewStudents"> <i className="fa fa-list" />  Students List </Link>

                                        <a href='assets/STUDENT_IMPORT_FORMAT.csv'>
                                            <button type="button" className="btn btn-labeled btn-danger m-b-5">
                                                <span className="btn-label">
                                                    <i className="glyphicon glyphicon-arrow-down" />
                                                </span>
                                                Download Sample File
                                            </button>
                                        </a>



                                    </div>
                                </div>
                                <ToastContainer />
                                <div className="panel-body" >
                                    <form className="col-sm-12" onSubmit={onSubmit}>

                                        <div className="">


                                            <div className="col-sm-4 form-group">
                                                <label>Class<span className='required_field'>*</span></label>
                                                <select className="form-control" id="exampleSelect1" value={classId} required name="class" onChange={(event) => { setClassId(event.target.value) }}>
                                                    <option value="">Select</option>
                                                    {
                                                        classes.length ?
                                                            classes.map((value) => {
                                                                return (<option key={value.id} value={value.id}>{value.name}</option>);
                                                            })
                                                            :
                                                            <option value="">Not Record Found!</option>
                                                    }
                                                </select>
                                                <p className='errorMessage'>{errors.classId}</p>
                                            </div>

                                            <div className='clearfix'></div>

                                            <div className="col-sm-4 form-group">
                                                <label>Section</label>
                                                <select className="form-control" id="exampleSelect1" value={section} onChange={(event) => { setSection(event.target.value) }}>
                                                    <option value="">Select</option>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                    <option value="D">D</option>
                                                </select>
                                                <p className='errorMessage'>{errors.section}</p>
                                            </div>


                                            <div className='clearfix'></div>


                                            <div className="col-sm-4 form-group">
                                                <label>Select Excel File(csv format)</label>
                                                <input type="file" id='excelFile' className="form-control"
                                                    onChange={(e) => setExcelFile(e.target.files[0])}
                                                    placeholder="file" required />
                                                <p className='errorMessage'>{errors.excelFile}</p>
                                            </div>










                                        </div>
                                        <div className="col-sm-12 reset-button">
                                            <div className="col-sm-4 form-group">
                                                {
                                                    !loading ? (
                                                        <button type='submit' className="btn btn-success">Import Student</button>)
                                                        :
                                                        (<div className='loader'>
                                                            <Circles
                                                                height="60"
                                                                width="60"
                                                                color="#4fa94d"
                                                                ariaLabel="circles-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                visible={loading}
                                                            />
                                                        </div>)
                                                }
                                            </div>
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> {/* /.content */}




            </div> {/* /.content-wrapper */}

        </div>

    )
}

export default ImportStudent;