import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../../App';


const FeesCard = () => {
    const navigate = useNavigate();
    const { studentID } = useContext(SchoolContext);
    const [feeCard, setFeecard] = useState([]);  
    const [feeCardStatus, setFeeCardStatus] = useState("");  
    const [studentDetails, setStudentDetails] = useState([]);


    const [loading1, setLoading1] = useState(false);

    useEffect(() => {
       handleRegNoChange();
    }, [studentID]);

    const handleRegNoChange = async () => {
        setLoading1(true);
        try {
           
            const userData = {
                studentID: studentID,
            }

            await Axios.post(api_url + 'student/feesCard', qs.stringify(userData))
                .then(resp => {                  
                    setLoading1(false);
                    if (resp.data['message'] == 'OK') {
                        setFeecard(resp.data.data);
                        setStudentDetails(resp.data.student_details[0]);                                         
                    }
                    else
                        if (resp.data.status == 'FEES_NOT_ASSIGNED') {                           
                            setFeecard([]);
                            setFeeCardStatus("Your fees card not generated yet");
                            setStudentDetails([]);
                        }
                        else
                            if (resp.data.status == 'INVALID_STUDENT') {
                            
                                setFeecard([]);
                                setFeeCardStatus("Invalid student, contact school admin");
                                setStudentDetails([]);
                            }
                            else {                               
                                setFeecard([]);
                                setFeeCardStatus("Invalid student, contact school admin");
                                setStudentDetails([]);
                            }

                });

        }
        catch (err) {
            console.log(err);
        }

    }
    
    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                {/* <ToastContainer /> */}
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Fees</h1>
                        <small>Fees Payment</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-body">

                                   


                                    {
                                        feeCard.length ? (<div className="table-responsive">

                                            <div className="inbox-avatar" style={{ borderBottom: '1px solid #eee', borderTop: '1px solid #eee', marginBottom: 10 }}>
                                                <img src="assets/dist/img/avatar.png" className="border-green hidden-xs hidden-sm" />
                                                <div className="inbox-avatar-text">
                                                    <div className="avatar-name" style={{ marginBottom: 3 }}>{studentDetails.name} ({studentDetails.reg_no})</div>
                                                    <div>
                                                        <span className="bg-green badge avatar-text">
                                                            Father Name  : {studentDetails.father_name}
                                                        </span>

                                                        <span className="badge avatar-text" style={{ backgroundColor: '#7A316F' }}>
                                                            Class  : {studentDetails.class_name}
                                                        </span>

                                                        <span className="bg-gray badge avatar-text">
                                                            Contact No  : {studentDetails.contact_no}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>



                                            {
                                                !loading1 ? (

                                                    <>                                                   

                                                        <table className="table table-bordered table-hover">
                                                            {/* <caption style={{ fontWeight: 'bold' }}>Total Payment: {totalPayAmount}</caption> */}
                                                            <thead>
                                                                <tr>
                                                                    <th>Fee Name</th>
                                                                    <th>Month</th>
                                                                    <th>Amount</th>

                                                                    <th>Paid</th>                                                            
                                                                    <th>Due</th>

                                                                    <th>Status</th>
                                                                    <th>Consession</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    feeCard.map((value, key) => {

                                                                        return (<tr key={key}
                                                                            style={{ backgroundColor: value.status == 'DUE' ? '#C70039' : '#618264', color:'#fff', fontWeight:'bold'}}
                                                                        >
                                                                            <td>{value.fee_name} </td>
                                                                            <td>{value.fee_months}</td>
                                                                            <td>{value.amount}</td>

                                                                            <td>{value.paid}</td>
                                                                           
                                                                            <td>{value.due}</td>

                                                                            <td>
                                                                                {value.status}                                                                        
                                                                            </td>

                                                                            <td>
                                                                                {value.concession}
                                                                            </td>



                                                                        </tr>);
                                                                    })
                                                                }

                                                                {/* <tr>
                                                                    <td colSpan="9" className='table_bottom_total'>Total {feeCard.length} records found</td>
                                                                </tr> */}


                                                            </tbody>
                                                        </table>
                                                    </>

                                                )
                                                    :
                                                    (<div className='loader' style={{ marginLeft: '20%' }}>
                                                        <Circles
                                                            height="60"
                                                            width="60"
                                                            color="#4fa94d"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={loading1}
                                                        />
                                                    </div>)
                                            }




                                        </div>) : (
                                            <div style={{ color: 'red' }}>{feeCardStatus}</div>
                                        )
                                    }









                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default FeesCard