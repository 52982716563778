import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';


const ShowAttendance = () => {

    const { schoolID, schoolSession } = useContext(SchoolContext);

    const [classes, setClasses] = useState([]);
    const [classId, setClassId] = useState("");

    const [randDep, setRandDep] = useState(0);
    const [attendance, setAttendance] = useState([]);
    const [attendanceAll, setattendanceAll] = useState([]);

    const [date, setDate] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(true);


    const getAttendanceData = async () => {
        setLoading1(true);
        try {
            if (schoolID < 1 || schoolID == null) {
                setRandDep(Math.random);
            }

            const userData = {
                date: date,
                school_id: schoolID,
                session: schoolSession,
            }

            await Axios.post(api_url + 'getAttendanceForSchool', qs.stringify(userData))
                .then(resp => {
                    setLoading1(false);
                    if (resp.data['message'] == 'OK') {
                        //console.log(resp.data.data);
                        setAttendance(resp.data.data);
                        setattendanceAll(resp.data.data);
                    }

                });

        }
        catch (err) {
            console.log(err);
        }

    }


    useEffect(() => {
        getAttendanceData();
        getClasses();
    }, [randDep]);



    const dateChangeHandler = (event) => {
        setDate(event.target.value);
        setSearchQuery('');
        setClassId('');
        setRandDep(Math.random);
    };

    const handleOnSearchInput = async text => {
        setSearchQuery(text);
        if (!text.trim()) {
            setSearchQuery('');
            setClassId('');
            setRandDep(Math.random());
        }

        const filteredData = attendanceAll.filter(item => {
            if ((item.reg_no.toLowerCase().includes(text.toLowerCase())) || (item.student_name.toLowerCase().includes(text.toLowerCase())) || (item.class_name.toLowerCase().includes(text.toLowerCase()))) {
                return item;
            }
        });


        if (filteredData.length) {
            setAttendance([...filteredData]);
        }
        else {
            setAttendance([]);
        }
    };


    const updateAttendanceStatus = (index, value) => {

        if (value == 'P') {
            setAttendance(
                attendance.map((topping, currentIndex) =>
                    currentIndex === index
                        ? { ...topping, attendance: 'P' }
                        : topping
                )
            )
        }
        else
            if (value == 'A') {
                setAttendance(
                    attendance.map((topping, currentIndex) =>
                        currentIndex === index
                            ? { ...topping, attendance: 'A' }
                            : topping
                    )
                )
            }

            else
                if (value == 'H') {
                    setAttendance(
                        attendance.map((topping, currentIndex) =>
                            currentIndex === index
                                ? { ...topping, attendance: 'H' }
                                : topping
                        )
                    )
                }


        //console.log(attendance);

    }


    const updateAttendance = () => {

        setLoading(true);

        if (date == '') {
            toast.warning('Please select attendance date', {
                position: "top-center",
            });
        }
        else {
            let partialDetails = attendance.map((
                { id, attendance }) => ({ id, attendance }));

            const userData = {
                student_data: partialDetails,
                date: date,
                sender_id: '1',
                sender_type: 'SCHOOL',
            }

            Axios.post(api_url + 'updateAttendance', qs.stringify(userData))
                .then(resp => {
                    setLoading(false);
                    if (resp.data.status == 'OK') {
                        setDate("");
                        toast.success('Attendance updated successfully', {
                            position: "top-center",
                        });

                        setAttendance([]);

                    }
                    else
                        if (resp.data.status == 'V_ERROR') {
                            toast.error('Cannot add attendance try again', {
                                position: "top-center",
                            });
                        }
                        else {
                            toast.error('Cannot update attendance try again', {
                                position: "top-center",
                            });
                        }

                });
        }
    }



    const getClasses = () => {
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }

        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getClasses', qs.stringify(userData))
            .then((response) => {
                setClasses(response.data.data)
            })
    }


    const classChangeHandler = (event) => {
        let text = event.target.value;
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }


        const filteredData = attendanceAll.filter(student => {
            if ((student.class_name == text)) {
                return student;
            }
        });


        if (filteredData.length) {
            setAttendance([...filteredData]);
        }
        else {
            setAttendance([]);
        }

        setClassId(text);

    };

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Attendance</h1>
                        <small>Attendance Report</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-body">

                                    <div className="row">
                                        <div className="panel-header">


                                            <div className="col-md-12 col-sm-6 col-xs-12">
                                                <div className="dataTables_length">


                                                    <div className="row">


                                                    <div className='col-lg-2 col-md-3 col-sm-6'>
                                                            <input type="date" className='form-control' title="Enter Attendance Date" name="date" required value={date} onChange={dateChangeHandler} />
                                                        </div>


                                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                                            <input type="text" className="form-control" placeholder="search by reg no, name, contact no or class" onChange={(event) => { handleOnSearchInput(event.target.value) }} />
                                                        </div>

                                                        <div className='col-lg-2 col-md-3 col-sm-6'>
                                                            <select className="form-control" value={classId} required name="class" onChange={classChangeHandler} >
                                                                <option value="">Select Class</option>
                                                                {
                                                                    classes.length ?
                                                                        classes.map((value) => {
                                                                            return (<option key={value.id} value={value.name}>{value.name}</option>);
                                                                        })
                                                                        :
                                                                        <option value="">Not Record Found!</option>
                                                                }
                                                            </select>
                                                        </div>


                                                      





                                                    </div>



                                                    {
                                                        attendance.length > 0 ?
                                                            (
                                                                <>
                                                                    {
                                                                        !loading ? (
                                                                            <button type="button" className="btn btn-success" style={{ marginTop: 10 }} onClick={() => { updateAttendance() }} >
                                                                                Edit Attendance
                                                                            </button>)
                                                                            :
                                                                            (<div className='loader'>
                                                                                <Circles
                                                                                    height="40"
                                                                                    width="40"
                                                                                    color="#4fa94d"
                                                                                    ariaLabel="circles-loading"
                                                                                    wrapperStyle={{}}
                                                                                    wrapperClass=""
                                                                                    visible={loading}
                                                                                />
                                                                            </div>)
                                                                    }
                                                                </>
                                                            ) : ''
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {
                                        date ? (<div className="table-responsive">




                                            {
                                                !loading1 ? (
                                                    <table className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Reg No</th>
                                                                <th>Class</th>

                                                                <th>Attendance</th>
                                                                <th>Date</th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                attendance.length ?
                                                                    attendance.map((value, key) => {

                                                                        return (<tr key={key}>
                                                                            <td>{value.student_name}</td>
                                                                            <td>{value.reg_no}</td>
                                                                            <td>{value.class_name}</td>

                                                                            <td>{/*value.attendance*/}


                                                                                <div className='attendance_options' style={{ paddingTop: 0 }}>


                                                                                    <div className='attendance_options_section'>
                                                                                        <label><b>Present</b></label>
                                                                                        <div>
                                                                                            <input
                                                                                                value='P'
                                                                                                type='radio'
                                                                                                checked={value.attendance == 'P' ? true : false}
                                                                                                onChange={() => updateAttendanceStatus(key, 'P')}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className='attendance_options_section'>
                                                                                        <label><b>Absent</b></label>
                                                                                        <div>
                                                                                            <input
                                                                                                value='A'
                                                                                                type='radio'
                                                                                                checked={value.attendance == 'A' ? true : false}
                                                                                                onChange={() => updateAttendanceStatus(key, 'A')}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className='attendance_options_section'>
                                                                                        <label><b>Halfday</b></label>
                                                                                        <div>
                                                                                            <input
                                                                                                value='H'
                                                                                                type='radio'
                                                                                                checked={value.attendance == 'H' ? true : false}
                                                                                                onChange={() => updateAttendanceStatus(key, 'H')}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                            </td>
                                                                            <td>{Moment(value.attendance_date).format("DD-MM-YYYY")}</td>


                                                                        </tr>);
                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan='9'>No record found!</td>
                                                                    </tr>
                                                            }

                                                            <tr>
                                                                <td colSpan="9" className='table_bottom_total'>Total {attendance.length} records found</td>
                                                            </tr>


                                                        </tbody>
                                                    </table>)
                                                    :
                                                    (<div className='loader' style={{ marginLeft: '20%' }}>
                                                        <Circles
                                                            height="60"
                                                            width="60"
                                                            color="#4fa94d"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={loading1}
                                                        />
                                                    </div>)
                                            }




                                        </div>) : (
                                            <div style={{ color: 'red' }}>Please select a date</div>
                                        )
                                    }






                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default ShowAttendance