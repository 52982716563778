import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';


const SchoolDetails = () => {
    const { schoolID, schoolSession } = useContext(SchoolContext);

    const [errors, setErrors] = useState([]);
    const [randDep, setRandDep] = useState(0);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [affiliatedTo, setAffiliatedTo] = useState("");
    const [address, setAddress] = useState("");
    const [schoolNo, setSchoolNo] = useState("");

    const [affilationNo, setAffilationNo] = useState("");
    const [regValidUpto, setRegValidUpto] = useState("");
    const [schoolType, setSchoolType] = useState("");

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }
        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
      if(schoolID)
      {
            Axios.post(api_url + 'getSchoolDetails', qs.stringify(userData))
            .then((response) => {            
               setName(response.data.data[0].name);
               setEmail(response.data.data[0].email);
               setPassword(response.data.data[0].password);

               setAffiliatedTo(response.data.data[0].affiliated_to);
               setAddress(response.data.data[0].address);
               setSchoolNo(response.data.data[0].school_no);

               setAffilationNo(response.data.data[0].affiliation_no);
               setRegValidUpto(response.data.data[0].reg_valid_upto);
               setSchoolType(response.data.data[0].school_type);        
            })
      }
       

    }, [randDep]);




    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        const userData = {
            name: name,
            email: email,
            password: password,
            affiliatedTo: affiliatedTo,

            address: address,
            schoolNo: schoolNo,
            affilationNo: affilationNo,

            regValidUpto: regValidUpto,
            schoolType: schoolType,

            school_id: schoolID,
            session: schoolSession,
        }

        Axios.post(api_url + 'UpdateSchoolDetails', qs.stringify(userData))
            .then(resp => {
                setLoading(false);
                if (resp.data.status == 'OK') {
                    toast.success('Updated successfully', {
                        position: "top-center",
                    });
                    setErrors([]);
                }
                else
                    if (resp.data.status == 'DUPLICATE') {
                        toast.error('Cannot create try again', {
                            position: "top-center",
                        });
                        setErrors(resp.data);
                    }
                    else
                        if (resp.data.status == 'V_ERROR') {
                            toast.error('Cannot create try again', {
                                position: "top-center",
                            });
                            setErrors(resp.data.data);
                        }
                        else {
                            toast.error('Cannot create try again', {
                                position: "top-center",
                            });
                            setErrors(resp.data.message);
                        }
                setRandDep(Math.random());
            });

    };

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>School</h1>
                        <small>School Details</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/WelcomeSchool"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">

                                <div className="panel-body">

                                    <div className="panel-body" >
                                        <form className="col-sm-12" onSubmit={onSubmit}>

                                            <div className="">
                                                <div className="col-sm-6 form-group">
                                                    <label>Name<span className='required_field'>*</span></label>
                                                    <input type="text" className="form-control" value={name} required autoFocus onChange={(event) => { setName(event.target.value) }} />
                                                    <p className='errorMessage'>{errors.name}</p>
                                                </div>
                                                <div className="col-sm-6 form-group">
                                                    <label>Email<span className='required_field'>*</span></label>
                                                    <input type="email" className="form-control" value={email} required onChange={(event) => { setEmail(event.target.value) }} />
                                                    <p className='errorMessage'>{errors.email}</p>
                                                </div>
                                                <div className="col-sm-6 form-group">
                                                    <label>Password<span className='required_field'>*</span></label>
                                                    <input type="text" className="form-control" value={password} required onChange={(event) => { setPassword(event.target.value) }} />
                                                    <p className='errorMessage'>{errors.password}</p>
                                                </div>

                                                <div className="col-sm-6 form-group">
                                                    <label>Affiliated To</label>
                                                    <input type="text" className="form-control" value={affiliatedTo} onChange={(event) => { setAffiliatedTo(event.target.value) }} />
                                                    <p className='errorMessage'>{errors.affiliatedTo}</p>
                                                </div>

                                                <div className="col-sm-6 form-group">
                                                    <label>Address</label>
                                                    <input type="text" className="form-control" value={address} onChange={(event) => { setAddress(event.target.value) }} />
                                                    <p className='errorMessage'>{errors.address}</p>
                                                </div>

                                                <div className="col-sm-6 form-group">
                                                    <label>School No</label>
                                                    <input type="number" className="form-control" value={schoolNo} onChange={(event) => { setSchoolNo(event.target.value) }} />
                                                    <p className='errorMessage'>{errors.schoolNo}</p>
                                                </div>

                                                <div className="col-sm-6 form-group">
                                                    <label>Affiliation No</label>
                                                    <input type="text" className="form-control" value={affilationNo} onChange={(event) => { setAffilationNo(event.target.value) }} />
                                                    <p className='errorMessage'>{errors.affilationNo}</p>
                                                </div>


                                                <div className="col-sm-6 form-group">
                                                    <label>Registration Valid Upto Date</label>
                                                    <input type="date" className="form-control" value={regValidUpto} onChange={(event) => { setRegValidUpto(event.target.value) }} />
                                                    <p className='errorMessage'>{errors.regValidUpto}</p>
                                                </div>

                                                <div className="col-sm-6 form-group">
                                                    <label>School Type</label>
                                                    <input type="text" className="form-control" value={schoolType} onChange={(event) => { setSchoolType(event.target.value) }} />
                                                    <p className='errorMessage'>{errors.schoolType}</p>
                                                </div>





                                            </div>
                                            <div className="col-sm-12 reset-button">
                                                {
                                                    !loading ? (
                                                        <button type='submit' className="btn btn-success">Update Details</button>)
                                                        :
                                                        (<div className='loader'>
                                                            <Circles
                                                                height="60"
                                                                width="60"
                                                                color="#4fa94d"
                                                                ariaLabel="circles-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                                visible={loading}
                                                            />
                                                        </div>)
                                                }

                                            </div>


                                        </form>
                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                </section>








            </div>


        </>
    )
}

export default SchoolDetails