import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';


const FeesPayment = () => {
    const navigate = useNavigate();
    const { schoolID, schoolSession } = useContext(SchoolContext);

    const [randDep, setRandDep] = useState(0);
    const [regNo, setRegNo] = useState("");
    const [feeCard, setFeecard] = useState([]);
    const [totalPayAmount, setTotalPayAmount] = useState(0);
    const [date, setDate] = useState(null);
    const [studentDetails, setStudentDetails] = useState([]);

    const [fine, setFine] = useState(0);

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);


    useEffect(() => {
        calculate_total_pay_amount();

    }, [feeCard]);

    const handleRegNoChange = async () => {
        setLoading1(true);
        try {
            if (schoolID < 1 || schoolID == null) {
                setRandDep(Math.random);
            }

            const userData = {
                regNo: regNo,
                schoolId: schoolID,
                session: schoolSession,
            }

            await Axios.post(api_url + 'studentFeesDetails', qs.stringify(userData))
                .then(resp => {
                    setLoading1(false);
                    if (resp.data['message'] == 'OK') {
                        if (resp.data.student_details[0].status == '') {
                            setFeecard(resp.data.data);
                            setStudentDetails(resp.data.student_details[0]);
                            setTotalPayAmount(0);
                            setFine(resp.data.fine)
                            //console.log(resp.data)
                        }
                        else {
                            setFeecard([]);
                            setStudentDetails([]);
                            alert("Student has leaved the school");
                        }
                    }
                    else
                        if (resp.data.status == 'FEES_NOT_ASSIGNED') {
                            toast.error('Please assign fees to classes', {
                                position: "top-center",
                            });
                            setFeecard([]);
                            setStudentDetails([]);
                        }
                        else
                            if (resp.data.status == 'INVALID_STUDENT') {
                                toast.error('Student not found', {
                                    position: "top-center",
                                });
                                setFeecard([]);
                                setStudentDetails([]);
                            }
                            else {
                                toast.error('Error occured in fees card setup', {
                                    position: "top-center",
                                });
                                setFeecard([]);
                                setStudentDetails([]);
                            }

                });

        }
        catch (err) {
            console.log(err);
        }

    }


    const updateFeeStatus = (index, value) => {
        if (value == 'DUE') {
            // document.getElementById("pay_amount"+index).removeAttribute("readonly");
            // document.getElementById("pay_concession"+index).removeAttribute("readonly");
            setFeecard(
                feeCard.map((topping, currentIndex) =>
                    currentIndex === index
                        ? { ...topping, status: 'DUE', pay_amount: 0, pay_concession: 0 }
                        : topping
                )
            )
        }
        else
            if (value == 'PAID') {
                // document.getElementById("pay_amount"+index).setAttribute("readonly",true);
                // document.getElementById("pay_concession"+index).setAttribute("readonly",true);
                setFeecard(
                    feeCard.map((topping, currentIndex) =>
                        currentIndex === index
                            ? { ...topping, status: 'PAID', pay_amount: topping.due, pay_concession: 0 }
                            : topping
                    )
                )
            }
    }


    const updateFeePayAmount = (payAmount, index, dueAmount, pay_concession = 0) => {
        payAmount = Number(payAmount);
        dueAmount = Number(dueAmount);
        pay_concession = Number(pay_concession);
        if (payAmount < 0) {
            payAmount = 0;
        }
        if (pay_concession < 0) {
            pay_concession = 0;
        }

        if ((payAmount + pay_concession) > dueAmount) {
            payAmount = dueAmount - pay_concession;
        }

        setFeecard(
            feeCard.map((topping, currentIndex) =>
                currentIndex === index
                    ? { ...topping, pay_amount: JSON.stringify(payAmount) }
                    : topping
            )
        )
    }






    const updateFeePayConcession = (payConcession, index, dueAmount, payAmount = 0) => {
        payAmount = Number(payAmount);
        dueAmount = Number(dueAmount);
        payConcession = Number(payConcession);

        if (payAmount < 0) {
            payAmount = 0;
        }
        if (payConcession < 0) {
            payConcession = 0;
        }

        if ((payAmount + payConcession) > dueAmount) {
            payConcession = dueAmount - payAmount;

        }
        setFeecard(
            feeCard.map((topping, currentIndex) =>
                currentIndex === index
                    ? { ...topping, pay_concession: JSON.stringify(payConcession) }
                    : topping
            )
        )

    }


    const calculate_total_pay_amount = () => {

        let sum = 0;
        for (let i = 0; i < feeCard.length; i++) {
            if (feeCard[i].pay_amount != undefined) {
                sum = sum + Number(feeCard[i].pay_amount);
            }
        }

        setTotalPayAmount(sum);

    }

    const payfees = () => {
        if (date == '' || date == null) {
            toast.warning('Please select some date', {
                position: "top-center",
            });
        }
        else {

            const userData = {
                fee_data: feeCard,
                fine: fine,
                date: date,
            }
            //console.log(userData);

            setLoading(true);
            Axios.post(api_url + 'updateFeesCard', qs.stringify(userData))
                .then(resp => {
                    setLoading(false);
                    if (resp.data.status == 'OK') {
                        toast.success('Updated successfully', {
                            position: "top-center",
                        });
                        navigate('/ViewReceipts');

                    }
                    else
                        if (resp.data.status == 'NO_UPDATE') {
                            toast.warning('Please update some fees', {
                                position: "top-center",
                            });

                        }
                        else
                            if (resp.data.status == 'V_ERROR') {
                                toast.error('Please select fee payment date', {
                                    position: "top-center",
                                });
                            }
                            else {
                                toast.error('Cannot update try again', {
                                    position: "top-center",
                                });
                            }

                });
        }
    }


    const regenerateFeesCard = async () => {
        setLoading1(true);
        try {
            if (schoolID < 1 || schoolID == null) {
                setRandDep(Math.random);
            }
            const userData = {
                regNo: regNo,
                schoolId: schoolID,
                session: schoolSession,
            }
            await Axios.post(api_url + 'regenerateFeesCard', qs.stringify(userData))
                .then(resp => {
                    setLoading1(false);
                    if (resp.data['status'] == 'ERROR') {
                        toast.error(resp.data['message'], {
                            position: "top-center",
                        });
                        // alert(resp.data['message']);                           
                    }
                    else
                        if (resp.data['status'] == 'OK') {
                            toast.success(resp.data['message'], {
                                position: "top-center",
                            });
                            setRegNo("");
                            setFeecard([]);
                            setStudentDetails([]);
                        }
                });

        }
        catch (err) {
            console.log(err);
        }

    }

    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>
            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Fees</h1>
                        <small>Fees Payment</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-body">

                                    <div className="row">
                                        <div className="panel-header">


                                            <div className="col-sm-6 col-xs-12">
                                                <div className="dataTables_length">
                                                    <div className="input-group custom-search-form">




                                                        <input type="text" className="form-control" value={regNo} placeholder="search by reg no" onChange={(event) => setRegNo(event.target.value)} />
                                                        <span className="input-group-btn" >


                                                            <>
                                                                {
                                                                    !loading1 ? (
                                                                        <button className="btn btn-primary" type="button" onClick={handleRegNoChange} style={{ marginTop: -5 }}>
                                                                            <span className="glyphicon glyphicon-search" />
                                                                        </button>
                                                                    )
                                                                        :
                                                                        (<div className='loader'>
                                                                            <Circles
                                                                                height="40"
                                                                                width="40"
                                                                                color="#4fa94d"
                                                                                ariaLabel="circles-loading"
                                                                                wrapperStyle={{}}
                                                                                wrapperClass=""
                                                                                visible={loading1}
                                                                            />
                                                                        </div>)
                                                                }
                                                            </>


                                                        </span>




                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>











                                    {
                                        feeCard.length ? (<div className="table-responsive">

                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                                <div className="inbox-avatar" style={{ border: '1px solid #eee', marginBottom: 10, paddingLeft: 10 }}>
                                                    <img src="assets/dist/img/avatar.png" className="border-green hidden-xs hidden-sm" />
                                                    <div className="inbox-avatar-text">
                                                        <div className="avatar-name" style={{ marginBottom: 3 }}>
                                                            {studentDetails.name} ({studentDetails.reg_no})

                                                        </div>
                                                        <div>
                                                            <span className="bg-green badge avatar-text">
                                                                Father Name  : {studentDetails.father_name}
                                                            </span>

                                                            <span className="badge avatar-text" style={{ backgroundColor: '#7A316F' }}>
                                                                Class  : {studentDetails.class_name}
                                                            </span>

                                                            <span className="bg-gray badge avatar-text">
                                                                Contact No  : {studentDetails.contact_no}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ marginLeft: '5%', marginRight: '20%' }}>
                                                    {
                                                        !loading1 ? (
                                                            totalPayAmount < 1 ? (<button className="btn btn-danger" type="button" onClick={regenerateFeesCard} style={{ marginTop: -5 }}>
                                                                Regenerate Fees Card
                                                            </button>) : null


                                                        )
                                                            :
                                                            (<div className='loader'>
                                                                <Circles
                                                                    height="40"
                                                                    width="40"
                                                                    color="#4fa94d"
                                                                    ariaLabel="circles-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=""
                                                                    visible={loading1}
                                                                />
                                                            </div>)
                                                    }
                                                </div>
                                            </div>



                                            {
                                                !loading1 ? (

                                                    <>
                                                        {
                                                            totalPayAmount > 0 ?
                                                                (

                                                                    <>


                                                                        <div className="input-group custom-search-form" style={{ display: 'flex' }}>

                                                                            <input type="date" className='form-control' style={{ width: 200 }} title="Enter Payment Date" name="date" required value={date} onChange={(e) => { setDate(e.target.value) }} />


                                                                            <div style={{ display: 'flex', marginLeft: '10px' }}>
                                                                                <label style={{ alignSelf: 'center', marginRight: '5px' }}>Fine:</label>
                                                                                <input type="number" className='form-control' style={{ width: 200 }} title="Fine Amount" name="fineAmount" value={fine} onChange={(e) => { setFine(e.target.value) }} />
                                                                            </div>


                                                                            {
                                                                                !loading ? (
                                                                                    <button type="button" className="btn btn-success" style={{ marginLeft: 7 }} onClick={payfees}>
                                                                                        Pay Fees
                                                                                    </button>
                                                                                )
                                                                                    :
                                                                                    (<div className='loader'>
                                                                                        <Circles
                                                                                            height="40"
                                                                                            width="40"
                                                                                            color="#4fa94d"
                                                                                            ariaLabel="circles-loading"
                                                                                            wrapperStyle={{}}
                                                                                            wrapperClass=""
                                                                                            visible={loading}
                                                                                        />
                                                                                    </div>)
                                                                            }
                                                                        </div>
                                                                    </>




                                                                )
                                                                :
                                                                null
                                                        }




                                                        <table className="table table-bordered table-hover">
                                                            <caption style={{ fontWeight: 'bold' }}>Total Payment: {totalPayAmount}</caption>
                                                            <thead>
                                                                <tr>
                                                                    <th>Fee Name</th>
                                                                    <th>Month</th>
                                                                    <th>Amount</th>

                                                                    <th>Paid</th>
                                                                    <th>Pay Now</th>
                                                                    <th>Due</th>

                                                                    <th>Status</th>
                                                                    <th>Consession</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    feeCard.map((value, key) => {

                                                                        return (<tr key={key}
                                                                            style={{ backgroundColor: value.status == 'DUE' ? '#C70039' : '#618264', color: '#fff', fontWeight: 'bold' }}
                                                                        >
                                                                            <td>{value.fee_name} </td>
                                                                            <td>{value.fee_months}</td>
                                                                            <td>{value.amount}</td>

                                                                            <td>{value.paid}</td>
                                                                            <td>
                                                                                <input type='number' value={value?.pay_amount} readOnly={value.status == 'PAID' ? true : false} id={"pay_amount" + key} onChange={(e) => updateFeePayAmount(e.target.value, key, value.due, value.pay_concession)} onKeyUp={calculate_total_pay_amount} className='form-control fee_card_input' />
                                                                            </td>
                                                                            <td>{value.due}</td>

                                                                            <td>
                                                                                {/* {value.status} */}

                                                                                <div className='attendance_options' style={{ paddingTop: 0 }}>
                                                                                    <div className='attendance_options_section'>
                                                                                        <label><b>DUE</b></label>
                                                                                        <div>
                                                                                            <input
                                                                                                value='DUE'
                                                                                                type='radio'
                                                                                                checked={value.status == 'DUE' ? true : false}
                                                                                                onChange={() => updateFeeStatus(key, 'DUE')}
                                                                                                disabled={value.status == 'PAID' ? true : false}
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className='attendance_options_section'>
                                                                                        <label><b>PAID</b></label>
                                                                                        <div>
                                                                                            <input
                                                                                                value='PAID'
                                                                                                type='radio'
                                                                                                checked={value.status == 'PAID' ? true : false}
                                                                                                onChange={() => updateFeeStatus(key, 'PAID')}
                                                                                                disabled={value.status == 'PAID' ? true : false}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                </div>


                                                                            </td>

                                                                            <td>
                                                                                {
                                                                                    value.concession > 0 || value.status == 'PAID'
                                                                                        ?
                                                                                        (value.concession)
                                                                                        :
                                                                                        (<input type='number' value={value?.pay_concession} readOnly={value.status == 'PAID' ? true : false} id={"pay_concession" + key} onChange={(e) => updateFeePayConcession(e.target.value, key, value.due, value.pay_amount)} className='form-control fee_card_input' />)
                                                                                }


                                                                            </td>



                                                                        </tr>);
                                                                    })
                                                                }

                                                                {/* <tr>
                                                                    <td colSpan="9" className='table_bottom_total'>Total {feeCard.length} records found</td>
                                                                </tr> */}


                                                            </tbody>
                                                        </table>
                                                    </>

                                                )
                                                    :
                                                    (<div className='loader' style={{ marginLeft: '20%' }}>
                                                        <Circles
                                                            height="60"
                                                            width="60"
                                                            color="#4fa94d"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={loading1}
                                                        />
                                                    </div>)
                                            }




                                        </div>) : (
                                            <div style={{ color: 'red' }}>Please enter reg no</div>
                                        )
                                    }









                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default FeesPayment