import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';



const ShowMessages = () => {

    const { schoolID, schoolSession, schoolUserType } = useContext(SchoolContext);

    const [randDep, setRandDep] = useState(0);
    const [messages, setMessages] = useState([]);
    const [loading1, setLoading1] = useState(true);

    const getMessageData = async () => {
        try {

            if (schoolID < 1 || schoolID == null) {
                setRandDep(Math.random);
            }
            const userData = {
                school_id: schoolID,
                session: schoolSession,
            }
            await  Axios.post(api_url + 'getMessagesForSchool', qs.stringify(userData))
                .then(resp => {
                    setLoading1(false);
                    if (resp.data['message'] == 'OK') {
                        console.log(resp.data.data);
                        setMessages(resp.data.data);                    
                    }

                });

        }
        catch (err) {
            console.log(err);
        }

    }

    useEffect(() => {
        getMessageData();
    }, [randDep]);


    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>

            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Messages</h1>
                        <small>Messsages list</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-body">

                                  
                                    <div className="table-responsive">
                                        {
                                            !loading1 ? (
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Message</th>
                                                            <th>Date</th>
                                                            <th>Url</th>

                                                            <th>Photo/File</th>
                                                            <th>Total Receivers</th>
                                                            <th>Details</th>
                                                        </tr>
                                                    </thead>


                                                    <tbody>

                                                        {
                                                            messages.length ?
                                                                messages.map((value, key) => {

                                                                    return (<tr key={key}>                                                            
                                                                        <td>{value.message}</td>
                                                                        <td>{Moment(value.created_at).format("DD-MM-YYYY, h:m:s")}</td>
                                                                        <td>                                                                
                                                                            {
                                                                            value.url ? (<a href={value.url} target='_blank'>{value.url}</a>) : null
                                                                            }
                                                                        </td>

                                                                        <td>                                                                
                                                                            {
                                                                            value.photo ? (<a href={value.photo} target='_blank'>File</a>) : null
                                                                            }
                                                                        </td>


                                                                        <td>{value.total_receiver}</td>


                                                                        <td>
                                                                            <Link
                                                                                to={{ pathname: `/ShowMessageStudents` }}
                                                                                state={{
                                                                                    scode: value.scode,                                                                                   
                                                                                }}>
                                                                                <button type="button" className="btn btn1 btn-info btn-xs" data-toggle="modal" data-target="#ordine"><i className="fa fa-eye" />
                                                                                </button>
                                                                            </Link>
                                                                            </td>

                                                                    </tr>);
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan='9'>No record found!</td>
                                                                </tr>
                                                        }

                                                        <tr>
                                                            <td colSpan="9" className='table_bottom_total'>Total {messages.length} records found</td>
                                                        </tr>


                                                    </tbody>




                                                </table>)
                                                :
                                                (<div className='loader' style={{ marginLeft: '12%' }}>
                                                    <Circles
                                                        height="60"
                                                        width="60"
                                                        color="#4fa94d"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={loading1}
                                                    />
                                                </div>)
                                        }




                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default ShowMessages